import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade'
import ShareIcon from '@material-ui/icons/Share';

import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input'
import Collapse from '@material-ui/core/Collapse';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import copy from 'copy-to-clipboard';
import BlockIcon from '@material-ui/icons/Block';
import i18n from '../utils/i18n'
const t = i18n.instance().t

class ShareForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      link: undefined,
      format: 'pdf'
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.documentGroup != this.props.documentGroup || prevProps.template != this.props.template) {
      if(!!this.props.documentGroup && !!this.props.template) {
        this.setupLink(this.props.documentGroup, this.props.template)
      } else {
        this.setState({ link: undefined })
      }
    }
  }

  setupLink = async (documentGroupId, templateId) => {
    this.props.startLoading('getting_link', t('share.loading'))
    let result = await this.props.getShareLink(documentGroupId, templateId)
    if(result.error) {
      this.setState({ link: undefined })
    } else {
      this.setState({ link: result.id })
    }

    this.props.stopLoading('getting_link')
  }

  createLink = async () => {
    this.props.startLoading('generating_link', t('share.generating'))
    const { documentGroup, template } = this.props
    let result = await this.props.createLink(documentGroup, template)
    if(!!result.error) {
      this.props.stopLoading('generating_link')
      return
    }
    this.setState({ link: result.id })
    this.props.stopLoading('generating_link')
  }

  copyLink = () => {
    const { link, format } = this.state
    const url = `https://easy.dataprivacybox.com/doc/${link}/${format}`
    copy(url)
    this.props.showSnackbar({ text: t('share.copied'), color: 'success' })
  }

  revokeLink = async () => {
    const { link } = this.state
    this.props.startLoading('revoking', t('share.revoking'))
    let result = await this.props.revokeLink(link)
    this.props.stopLoading('revoking')
    if(!result.error) {
      this.setState({ link: undefined })
      this.props.showSnackbar(t('share.revoked'))
    } else {
      this.props.showSnackbar(result.error.message)
    }
  }

  render() {
    const { onClose, open } = this.props
    const { link, format } = this.state
    return (
      <Dialog aria-labelledby="message-dialog" open={open} onClose={onClose}>
        <div className="popup">
          { !!link ? (
            <div className="column">
              <FormControl component="fieldset">
                <RadioGroup
                  value={format}
                  onChange={(t) => this.setState({ format: t.target.value }) }
                  row>
                  <FormControlLabel
                    value={'pdf'}
                    control={ <Radio color="primary" />}
                    label={'pdf'}
                  />
                  <FormControlLabel
                    value={'docx'}
                    control={ <Radio color="primary" />}
                    label={'docx'}
                  />
                  <FormControlLabel
                    value={'odt'}
                    control={ <Radio color="primary" />}
                    label={'odt'}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl className="stretch mt-2">
                <InputLabel htmlFor="share_url">{ t('share.url_label') }</InputLabel>
                <Input
                  className="stretch"
                  id="share_url"
                  value={`https://easy.dataprivacybox.com/doc/${link}/${format}`}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip title={t('share.copy_link')}>
                        <IconButton onClick={this.copyLink}>
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          ) : (
            <div className="column stretch">
              <span>{ t('share.no_url') }</span>
            </div>
          ) }
          <Grid className="mt-3" container spacing={2}>
            <Grid item>
              <Button onClick={onClose}>{ t('general.close') }</Button>
            </Grid>
              <Grid item>
                { !!link ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.revokeLink}
                    endIcon={<BlockIcon />}>
                    { t('share.revoke') }
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.createLink}
                    endIcon={<ShareIcon />}>
                    { t('share.create') }
                  </Button>
                ) }
              </Grid>
          </Grid>
        </div>
      </Dialog>
    )
  }
}

export default ShareForm
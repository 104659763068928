import firebase from "./firebase";
import { currentUserUid } from "./auth";

import config from "../config.json";

import moment from "moment";
import { times } from "lodash";

const ENV = config.env;
const DOCUMENT_GROUPS_COL = "document_groups";
const USERS_COL = "users";
const PARTNERS_COL = "partners";
const LOCALES_COL = "locales";
const COUNTRIES_COL = "countries";
const USER_DOCUMENT_GROUPS_COL = "user_document_groups";
const SHARE_LINKS_COL = "share_links";
const CATEGORIES_COL = "categories";

// --MARK-- DATABASE INTERACTION

function listenForCollection(collection, listener) {
  return collection.onSnapshot((snapshot) => {
    let objects = {};
    snapshot.forEach((doc) => {
      let obj = doc.data();
      objects[doc.id] = obj;
    });
    listener(objects);
  });
}

function listenForDocument(document, listener) {
  return document.onSnapshot((snapshot) => {
    listener(snapshot.data());
  });
}

async function fetchCollection(collection) {
  try {
    let snapshot = await collection.get();
    let objects = {};
    snapshot.forEach((doc) => {
      objects[doc.id] = doc.data();
    });
    return objects;
  } catch (err) {
    return { error: err };
  }
}

async function fetchDocument(document) {
  try {
    let snapshot = await document.get();
    return snapshot.data();
  } catch (err) {
    return { error: err };
  }
}

async function updateObject(collection, object, id) {
  const timestamp = moment().valueOf();
  // if(!object.meta) {
  //   object.meta = {}
  // }
  try {
    if (!id) {
      object.meta = {
        created: timestamp,
        updated: timestamp,
      };
      let doc = await collection.add(object);
      id = doc.id;
    } else {
      object["meta.updated"] = timestamp;
      await collection.doc(id).update(object);
    }
    return { id };
  } catch (err) {
    return { error: err };
  }
}

async function updateDocument(document, object) {
  const timestamp = moment().valueOf();
  try {
    let snapshot = await document.get();
    if (!snapshot.exists) {
      object.meta = {
        created: timestamp,
        updated: timestamp,
      };
      await document.set(object);
    } else {
      object["meta.updated"] = timestamp;
      await document.update(object);
    }
    return {};
  } catch (err) {
    return { error: err };
  }
}

async function deleteObject(collection, id) {
  try {
    await collection.doc(id).delete();
    return {};
  } catch (err) {
    return { error: err };
  }
}

// --MARK-- USER DOCUMENT GROUPS

function listenForUserDocumentGroups(partnerId, listener) {
  let collection = envBase()
    .collection(USER_DOCUMENT_GROUPS_COL)
    .where("client", "==", currentUserUid())
    .where("partner", "==", partnerId);
  return listenForCollection(collection, listener);
}

function listenForUserDocumentGroup(groupId, listener) {
  let document = envBase().collection(USER_DOCUMENT_GROUPS_COL).doc(groupId);
  return listenForDocument(document, listener);
}

async function updateUserDocumentGroup(data, id) {
  const collection = envBase().collection(USER_DOCUMENT_GROUPS_COL);
  return await updateObject(collection, data, id);
}

// --MARK-- PARTNER

async function fetchPartnerWithUrl(url) {
  let snapshot = await envBase()
    .collection(PARTNERS_COL)
    .where("url", "==", url.toLowerCase())
    .get();
  let partners = [];
  snapshot.forEach((doc) => {
    let partner = doc.data();
    partner.id = doc.id;
    partners.push(partner);
  });
  if (partners.length === 1) {
    return partners[0];
  } else {
    return null;
  }
}

// --MARK-- USER

const listenForUserData = (listener) => {
  const document = base().collection(USERS_COL).doc(currentUserUid());
  return listenForDocument(document, listener);
};

const updateUserData = async (data, logo) => {
  if (!!logo && !!logo.data) {
    let uploadResult = await uploadFile(
      logo.data,
      `client_${currentUserUid()}_image_${ENV}`,
      logo.format,
      "/"
    );
    if (!uploadResult.error) {
      data.logo = uploadResult.url;
    } else {
      console.log(uploadResult);
    }
  }
  const document = base().collection(USERS_COL).doc(currentUserUid());
  return updateDocument(document, data);
};

const fetchDocumentGroups = async (partner) => {
  let groups = {};
  for (let i in partner.groups) {
    let document = envBase()
      .collection(DOCUMENT_GROUPS_COL)
      .doc(partner.groups[i]);
    let doc = await fetchDocument(document);
    groups[partner.groups[i]] = doc;
  }
  return groups;
};

const fetchCategories = async (partner) => {
  const collection = envBase().collection(CATEGORIES_COL)
  return await fetchCollection(collection)
}

const deleteUserDocumentGroup = async (groupId) => {
  let collection = envBase().collection(USER_DOCUMENT_GROUPS_COL);
  return await deleteObject(collection, groupId);
};

const fetchLocales = async () => {
  let collection = firebase.firestore().collection(LOCALES_COL);
  return await fetchCollection(collection);
};

const fetchCountries = async () => {
  let collection = firebase.firestore().collection(COUNTRIES_COL);
  return await fetchCollection(collection);
};

const base = () => {
  return firebase.firestore();
};

const envBase = () => {
  return firebase.firestore().collection("environments").doc(ENV);
};

const uploadFile = async (data, name, format, path) => {
  try {
    let fileRef = firebase.storage().ref().child(`${path}/${name}.${format}`);
    let parts = data.split(",");
    let base64data;
    if (parts.length == 2) {
      base64data = parts[1];
    } else {
      base64data = data;
    }
    await fileRef.putString(base64data, "base64");
    let downloadUrl = await fileRef.getDownloadURL();
    return { url: downloadUrl };
  } catch (err) {
    console.log("image upload error", err);
    return { error: err };
  }
};

async function getShareLink(userDocumentGroupId, documentId) {
  try {
    let snapshot = await base()
      .collection(SHARE_LINKS_COL)
      .where("user_document_group", "==", userDocumentGroupId)
      .where("document", "==", documentId)
      .where("active", "==", true)
      .where("user", "==", currentUserUid())
      .where("environment", "==", ENV)
      .get();
    let links = [];
    snapshot.forEach((doc) => {
      links.push(doc.id);
    });
    if (links.length > 0) {
      return { id: links[0] };
    } else {
      return {
        error: { code: "no-link", message: "No share links for this document" },
      };
    }
  } catch (err) {
    return { error: err };
  }
}

async function createShareLink(userDocumentGroupId, documentId) {
  try {
    let snapshot = await base().collection(SHARE_LINKS_COL).add({
      user: currentUserUid(),
      user_document_group: userDocumentGroupId,
      document: documentId,
      active: true,
      environment: ENV,
    });
    return { id: snapshot.id };
  } catch (err) {
    return { error: err };
  }
}

async function revokeShareLink(linkId) {
  try {
    await base().collection(SHARE_LINKS_COL).doc(linkId).update({
      active: false,
    });
    return {};
  } catch (err) {
    return { error: err };
  }
}

export {
  listenForUserDocumentGroups,
  listenForUserDocumentGroup,
  updateUserDocumentGroup,
  deleteUserDocumentGroup,
  listenForUserData,
  updateUserData,
  fetchCountries,
  fetchLocales,
  fetchPartnerWithUrl,
  fetchDocumentGroups,
  getShareLink,
  createShareLink,
  revokeShareLink,
  fetchCategories
};

import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';

import CircularProgress from '@material-ui/core/CircularProgress';

import i18n from '../utils/i18n'
const t = i18n.instance().t

class Loading extends Component {

  render() {
    const { visible, message, type, empty } = this.props
    return (

      <Dialog aria-labelledby="error-dialog-question" open={visible} disableBackdropClick={true} disableEscapeKeyDown={true}>
        <div className="popup centered">
          { !empty ? (
            <h4 id="error-dialog-question" className="mb-3">{ message ? message : t('general.loading') }</h4>
          ) : null }
          { !! type && type === 'fetch' ? (
          <lottie-player
            style={{ width: 200, height: 200, margin: 'auto' }}
            src="../../assets/animations/spinner.json" background="transparent" speed="1" loop autoplay >
          </lottie-player>
          ) : (
            <CircularProgress className="loading-spinner" />
          ) }
        </div>
      </Dialog>
    )
  }
}

export default Loading

import firebase from 'firebase/app'
require("firebase/auth")
require("firebase/firestore")
require("firebase/functions")
require("firebase/storage")

const firebaseConfig = {
  apiKey: "AIzaSyDkIWWD-bHJht8KJ0Rl-X3XmeVTyq-Rlic",
  authDomain: "data-privacy-box-easy.firebaseapp.com",
  databaseURL: "https://data-privacy-box-easy.firebaseio.com",
  projectId: "data-privacy-box-easy",
  storageBucket: "data-privacy-box-easy.appspot.com",
  messagingSenderId: "957212158416",
  appId: "1:957212158416:web:efa624867ce36cd74331d5",
  measurementId: "G-ZP7YMCJM3B"
}

const firInstance = firebase.initializeApp(firebaseConfig);

export default firInstance
import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import _ from 'lodash'
import LinearProgress from '@material-ui/core/LinearProgress';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Collapse from '@material-ui/core/Collapse'
import 'moment/locale/fr';
import 'moment/locale/nl';

import i18n from '../utils/i18n'

import * as functions from '../services/functions'
import * as database from '../services/database'

import { languageCodeFromLocale } from '../utils'
import CircularProgress from '@material-ui/core/CircularProgress'

const t = i18n.instance().t

const DocumentBlock = props => {

  const { documentGroup, group } = props


  const [moreMenuAnchor, setMoreMenuAnchor] = useState(null)
  const [expanded, setExpanded] = useState(true)
  const [downloadMenu, setDownloadMenu] = useState({
    anchor: null,
    urls: {}
  })

  const [descriptionPopup, setDescriptionPopup] = useState({
    isOpen: false,
    document: null
  })

  const [generating, setGenerating] = useState({})

  const showMenu = (e) => {
    setMoreMenuAnchor(e.target)
  }

  const closeMenu = () => {
    setMoreMenuAnchor(null)
  }

  const onDelete = () => {
    const { deleteGroup } = props
    deleteGroup(group, documentGroup.id)
    closeMenu()
  }

  const onEdit = () => {
    const { editDocument } = props
    editDocument()
    closeMenu()
  }

  const openDownloadMenu = (docId) => (e) => {
    setDownloadMenu({
      anchor: e.target,
      urls: documentGroup.docs[docId].download_urls
    })
  }

  const shareDocument = (docId) => (e) => {
    const { shareDocument } = props
    shareDocument(documentGroup.id, docId)
  }

  const closeDownloadMenu = () => {
    setDownloadMenu({
      anchor: null,
      urls: {}
    })
  }

  const generateDocument = (id) => {
    let g = generating
    g[id] = true
    // startLoading('generating_document', t('document_block.generation_starting'))
    let listener = database.listenForUserDocumentGroup(documentGroup.id, grp => {
      if(grp.docs[id].status === 'generating') {
        // stopLoading('generating_document')
        let g = generating
        g[id] = false
        setGenerating({...g})
        listener()

      }
    })
    setGenerating({...g})
    functions.generateDocument(documentGroup, group, id)
  }

  const downloadDocument = async (url) => {
    closeDownloadMenu()
    window.open(url, '_blank')
  }

  const toggleExpansion = () => {
    setExpanded(!expanded)
  }

  const showDescription = (doc) => () => {
    setDescriptionPopup({
      isOpen: true,
      document: doc
    })
  }

  const closeDescription = () => {
    setDescriptionPopup({
      isOpen: false,
      document: null
    })
  }

  const renderHeader = () => {
    const flag = group.locale.split('_')[1].toLowerCase();

    return (
      <Grid container direction="row" className="stretch document-block-header">
        <Grid item sm={2}>
          <div className="row">
            <div className={`document-block-status-dot ${documentGroup.complete ? 'complete' : 'draft' }`} />
            <span className="document-block-status">{ documentGroup.complete ? t('document_block.status_complete') : t('document_block.status_draft') }</span>
          </div>
        </Grid>
        <Grid item sm={3}>
          <h5 className={`document-block-title ${documentGroup.complete ? 'clickable' : ''}`} onClick={documentGroup.complete ? toggleExpansion : () => {}}>{ !!group ? group.name : '' }</h5>
        </Grid>
        <Grid item  sm={1} className="row">
          <div className="row centered">
            <img className="document-block-flag" src={`/assets/images/${flag}.png`} />
            <div className="document-block-separator" />
            <span className="document-block-language">{ languageCodeFromLocale(group.locale).toUpperCase() }</span>
          </div>

        </Grid>
        <Grid item sm={1}>
          { !!documentGroup.docs && Object.keys(documentGroup.docs).length > 0 ? (
            <div className="row">
              <span className="document-block-docs">{ Object.keys(documentGroup.docs).length }</span>
              <div className="document-block-separator" />
              <InsertDriveFileIcon fontSize="small" color="action" />
            </div>
          ) : null }
        </Grid>
        <Grid item sm={1}>
          <span className="document-block-date">{ printDate(documentGroup.created) }</span>
        </Grid>
        <Grid item sm={2} className="row justify-end">
          <Button variant="contained" color="primary" onClick={onEdit} className="flat-button primary capitalized small-button bold-button" size="small">{ t('document_block.action_edit').toUpperCase() }</Button>
          <IconButton onClick={showMenu}>
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="document-group-menu"
            anchorEl={moreMenuAnchor}
            keepMounted
            open={Boolean(moreMenuAnchor)}
            onClose={closeMenu}
            >
              <MenuItem dense className="menu-item-destructive" onClick={onDelete}>{ t('document_block.action_delete') }</MenuItem>
            </Menu>
        </Grid>
      </Grid>
    )
  }

  const renderDocs = () => {
    return (
      <div className="document-block-docs-container">
        { _.orderBy(documentGroup.docs, ['name']).map((doc, di) => {
          return (
            <Grid container direction="row" spacing={2} className="document-block-doc-row" key={`document_row_${doc.id}`}>
              <Grid item sm={8}>
                <span className="document-block-doc-title">{ doc.name }</span>
              </Grid>
              <Grid item sm={1}>
                { !!doc.description ? (
                  <div className="ml-1 document-block-doc-description-button" onClick={showDescription(doc)}>
                    <span className="document-block-doc-description-button-label">?</span>
                  </div>
                ) : null }
              </Grid>
              <Grid item sm={3} className="document-block-doc-row-buttons">
                { doc.status === 'empty' ? (
                  <Button className="small-button" color="primary" endIcon={generating[doc.id] ? <CircularProgress size={12} color="primary" /> : null} disabled={generating[doc.id]} variant="outlined" size="small" onClick={() => { generateDocument(doc.id) }}>{ t('document_block.generate_document') }</Button>
                ) : doc.status === 'generating' ? (
                  <div className="document-progress-container">
                    <span className="progress-label primary-title">{ t('document_form.progress', { progress: doc.progress.toFixed(0) }) }</span>
                    <LinearProgress className="mt-1 rounded" variant="determinate" value={doc.progress} />
                  </div>
                ) : doc.status === 'complete' ? (
                  <div className="row">
                    <Button
                      variant="contained"
                      color="primary"
                      className="flat-button primary mr-2 small-button"
                      onClick={openDownloadMenu(doc.id)}>
                      { t('document_block.download') }
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      className="flat-button primary small-button"
                      onClick={shareDocument(doc.id)}>
                      { t('document_block.share') }
                    </Button>
                  </div>
                ) : null }
              </Grid>
            </Grid>
          )
        })}
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <Grid container direction="row" justify="space-between" alignItems="center" className="document-block-footer">
        <div>
          { documentGroup.meta && documentGroup.meta.created && <span className="document-block-footer-label">{ t('document_block.created', { date: printDate(documentGroup.meta.created)}) }</span> }
        </div>
        <div>
          { documentGroup.meta && documentGroup.meta.updated && <span className="document-block-footer-label">{ t('document_block.updated', { date: printDate(documentGroup.meta.updated)}) }</span> }
        </div>
        <div className="row centered">
          <span className="document-block-footer-label">{ t('document_block.available_until', { date: printDate(documentGroup.expires)}) }</span>
          <div className="document-block-separator" />
          <Button className="plain-button primary small" onClick={props.onExpirationOpen}>{ t('general.change') }</Button>
        </div>
        <div />
      </Grid>
    )
  }

  const renderDescription = () => {
    return (
      <Dialog open={descriptionPopup.isOpen} onClose={closeDescription} onEscapeKeyDown={closeDescription}>
        <Card>
          { !!descriptionPopup.document ? (
            <CardContent className="stretch column">
              <span className="document-block-doc-title">{ descriptionPopup.document.name }</span>
              <span className="document-block-doc-description">{ descriptionPopup.document.description }</span>
            </CardContent>
          ) : null }
        </Card>
      </Dialog>
    )
  }

  return (
    <div className="column document-block mt-3">
      { renderHeader() }
      <Collapse in={expanded}>
        { !!documentGroup.docs && Object.keys(documentGroup.docs).length > 0 ? renderDocs() : null }
        { documentGroup.complete ? renderFooter() : null }
      </Collapse>
      <Menu
        id="document-menu"
        anchorEl={downloadMenu.anchor}
        keepMounted
        open={Boolean(downloadMenu.anchor)}
        onClose={closeDownloadMenu}
      >
        { Object.keys(downloadMenu.urls).map((format, fi) => {
          return (
            <MenuItem key={`download_menu_item_${fi}`} onClick={() => { downloadDocument(downloadMenu.urls[format]) }}>{ format }</MenuItem>
          )
        })}
      </Menu>
      { renderDescription() }
    </div>
  )
}

const printDate = (date) => {
  moment.locale(i18n.instance().language)
  // dev todo switch
  // return moment(date).format('DD.MM.YYYY HH:mm')
  // production
  return moment(date).format('D MMM YYYY')
}


export default DocumentBlock

import firebase from './firebase'

const auth = firebase.auth()

const createUserStateChangedListener = (listener) => {
  return auth.onAuthStateChanged(listener)
}

const isUserSignedIn = () => {
  return !!auth.currentUser
}

const signInWithEmailAndPassword = async (email, password) => {
  try {
    let result = await auth.signInWithEmailAndPassword(email, password)
    return result
  } catch(err) {
    return { error: err }
  }
}

const createUserWithEmailAndPassword = async (email, password) => {
  try {
    let result = await auth.createUserWithEmailAndPassword(email, password)
    return result
  } catch(err) {
    return { error: err }
  }
}

const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email)
    return {}
  } catch(err) {
    return { error: err }
  }
}

const signOut = async () => {
  try {
    await auth.signOut()
    return {}
  } catch(err) {
    return { error: err }
  }
}

const currentUser = () => {
  return auth.currentUser
}

const currentUserUid = () => {
  if(!auth.currentUser) {
    return 'anonymous user'
  }
  return auth.currentUser.uid
}

export {
  isUserSignedIn,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserStateChangedListener,
  signOut,
  currentUser,
  currentUserUid
}
import React, { useState, useEffect } from "react";

import _ from "lodash";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { TextField, RadioGroup, ButtonGroup } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import MomentUtils from "@date-io/moment";
import Collapse from "@material-ui/core/Collapse";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Fade from "@material-ui/core/Fade";
import QuestionsSidebar from "../components/QuestionsSidebar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import i18n from "../utils/i18n";
import {
  languageFromLocale,
  sortedArrayFromObject,
  paramsFromSearch,
  updateUrlParams,
  partnerCopySuffix,
  isPartnerSNI,
} from "../utils";
import Question from "../components/Question";
import moment from "moment";
import { currentUserUid } from "../services/auth";
import Masonry from "react-masonry-css";
import { GroupBlock } from "../components";
import { updateUserDocumentGroup } from "../services/database";

const t = i18n.instance().t;

const FORM_STAGE = {
  Loading: -1,
  Language: 0,
  Category: 1,
  Group: 2,
  Questions: 3,
  Complete: 4,
};

const DocumentForm = (props) => {
  const {
    startLoading,
    stopLoading,
    showSnackbar,
    showDialog,
    hideDialog,
    showError,
    partner,
    history,
    historyPush,
    userData,
  } = props;

  const [locales, setLocales] = useState([]);
  const [categories, setCategories] = useState([]);
  const [documentGroups, setDocumentGroups] = useState([]);
  const [group, setGroup] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [userDocumentGroup, setUserDocumentGroup] = useState(null);
  const [stage, setStage] = useState(FORM_STAGE.Loading);
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [answers, setAnswers] = useState({});

  const [locale, setLocale] = useState("");
  const [category, setCategory] = useState("")
  const [path, setPath] = useState("");

  useEffect(() => {
    // url query changed
    navigate();
  }, [props.history.location]);

  useEffect(() => {
    navigate();
  }, [props.documentGroups, props.userDocumentGroups]);

  const navigate = () => {
    if (!props.documentGroups || !props.userDocumentGroups) {
      return;
    }
    let params = paramsFromSearch(history.location.search);
    let loc;
    if (!!params.locale) {
      loc = params.locale;
    }
    let cat = params.category || ''
    let p = pathFromLocation(history.location);
    setPath(p);

    setLocale(params.locale);
    setCategory(cat)

    if (p === "mydocument") {
      let id = idFromLocation();
      let userDocGroup = props.userDocumentGroups[id];
      if (!userDocGroup) {
        // todo show error and go to clean doc creation
        setupEmpty(FORM_STAGE.Language);
        return;
      }
      userDocGroup.id = id;
      setupWithUserDocumentGroup(userDocGroup);
    } else if (p === "document") {
      let id = idFromLocation();
      if (!id) {
        if (!loc) {
          setupEmpty(FORM_STAGE.Language);
        } else if(!cat) {
          setupEmpty(FORM_STAGE.Category)
        } else {
          setupEmpty(FORM_STAGE.Group);
        }
      } else {
        let docGroup = props.documentGroups[id];
        if (!docGroup) {
          // todo show error and reset
          setupEmpty(FORM_STAGE.Language);
          return;
        }
        setupWithDocumentGroup(docGroup);
      }
    }
  };

  const pathFromLocation = () => {
    let components = history.location.pathname.split("/");
    let pth = components[3];
    return pth;
  };

  const idFromLocation = () => {
    let components = history.location.pathname.split("/");
    let id = components[4];
    return id;
  };

  useEffect(() => {
    const allCategories = sortedArrayFromObject(props.categories, "name", true);
    const filteredCategories = []
    const documentGroupIds = documentGroups.map(group => group.id)
    for(let i = 0; i < allCategories.length; i++) {
      const categoryGroups = allCategories[i].groups || []
      for(let gId of categoryGroups) {
        if(documentGroupIds.includes(gId)) {
          filteredCategories.push(allCategories[i])
          break
        }
      }
    }
    setCategories([...filteredCategories]);
  }, [props.categories, documentGroups]);

  useEffect(() => {
    let grps = sortedArrayFromObject(props.documentGroups, "name", true);
    let locs = [];
    for (let i in grps) {
      if (locs.indexOf(grps[i].locale) === -1) {
        locs.push(grps[i].locale);
      }
    }
    setLocales([...locs]);
    setDocumentGroups([...grps]);
  }, [props.documentGroups]);

  const setupEmpty = (stg) => {
    setUserDocumentGroup(null);
    setGroup(null);
    setAnswers({});
    setQuestions([]);
    setCurrentQuestion(-1);
    setStage(stg);
  };

  const setupWithUserDocumentGroup = (userDocGroup) => {
    let docGroup = props.documentGroups[userDocGroup.group];
    if (!docGroup) {
      // todo show error and reset
      setupEmpty(FORM_STAGE.Language);
      return;
    }
    let qs = questionsArray(docGroup);
    setUserDocumentGroup({ ...userDocGroup });
    setGroup({ ...docGroup });
    setAnswers(userDocGroup.answers);
    setQuestions([...qs]);
    setCurrentQuestion(0);
    setStage(FORM_STAGE.Questions);
  };

  const setupWithDocumentGroup = (docGroup) => {
    let qs = questionsArray(docGroup);
    setUserDocumentGroup(null);
    setGroup({ ...docGroup });
    setAnswers(defaultAnswers(qs));
    setQuestions([...qs]);
    setCurrentQuestion(0);
    setStage(FORM_STAGE.Questions);
  };

  const defaultAnswers = (qs) => {
    let as = {};
    for (let i in qs) {
      as[qs[i].key] = defaultAnswerForQuestion(qs[i]);
    }
    return as;
  };

  const userDataForField = (field, question) => {
    switch (field) {
      case "title": {
        // select type question
        let val = userData.title;
        if (question.type === "select") {
          if (!val) {
            return -1;
          }
          for (let i in question.options) {
            if (question.options[i] === t(`profile.field_title_${val}`)) {
              return parseInt(i);
            }
          }
          return -1;
        } else {
          return val;
        }
      }
      case "name_first": {
        return userData.name?.first;
      }
      case "name_last": {
        return userData.name?.last;
      }
      case "occupation": {
        return userData.occupation;
      }
      case "enterprise": {
        return userData.enterprise?.name;
      }
      case "enterprise_type": {
        // select
        let val = userData.enterprise?.type;
        if (question.type === "select") {
          if (!val) {
            return -1;
          }
          for (let i in question.options) {
            if (
              question.options[i] === t(`profile.field_enterprise_type_${val}`)
            ) {
              return parseInt(i);
            }
          }
          return -1;
        } else {
          return val;
        }
      }
      case "address_street": {
        return userData.address?.street;
      }
      case "address_number": {
        return userData.address?.number;
      }
      case "address_zip": {
        return userData.address?.zip;
      }
      case "address_city": {
        return userData.address?.city;
      }
      case "email": {
        return userData.contact?.email;
      }
      case "phone": {
        return userData.contact?.phone;
      }
      case "website": {
        return userData.contact?.website;
      }
      case "enterprise_number": {
        return userData.enterprise?.enterprise_number;
      }
      case "vat_number": {
        return userData.vat_number;
      }
      case "enterprise_contact_phone":
        return userData.enterprise?.contact_phone;
      case "enterprise_contact_email":
        return userData.enterprise?.contact_email;
      case "enterprise_contact_position":
        return userData.enterprise?.contact_position;
      default:
        return "";
    }
  };

  const defaultAnswerForQuestion = (question) => {
    if (question.user_data && !!userData) {
      let answer = userDataForField(question.user_data, question);
      if (!!answer) {
        return answer;
      }
    }
    switch (question.type) {
      case "string":
        return "";
      case "number":
        return "";
      // case 'price':
      //   return {
      //     currency: 'EUR',
      //     amount: ''
      //   }
      case "date":
        return null;
      case "multiselect":
        return [];
      case "select":
        return 0;
      default:
        return "";
    }
  };

  const onAnswerChanged = (value, questionKey) => {
    let a = answers;
    a[questionKey] = value;
    setAnswers({ ...a });
  };

  const submitQuestion = () => {
    let question = questions[currentQuestion];
    let answer = answers[question.key];
    if (!isQuestionComplete(question, answer)) {
      // todo show error
      showSnackbar({
        text: t("document_form.answer_incomplete"),
        color: "warning",
      });
      return;
    }
    updateQuestions(1);
  };

  const questionsArray = (grp) => {
    let blocks = grp.form;
    let conditions = grp.conditions;

    let fb = [];
    for (let bi in blocks) {
      let b = blocks[bi];
      if (!!b.condition) {
        let blockCompliesToConditions = true;
        let condition = conditionForKey(conditions, b.condition);
        if (!condition) {
          blockCompliesToConditions = true;
        } else if (condition.relation === "eq") {
          blockCompliesToConditions =
            answers[condition.question] == condition.value;
        } else if (condition.relation === "less") {
          blockCompliesToConditions =
            answers[condition.question] < parseInt(condition.value);
        } else if (condition.relation === "more") {
          blockCompliesToConditions =
            answers[condition.question] > parseInt(condition.value);
        } else if (condition.relation === "cont") {
          blockCompliesToConditions = !!answers[condition.question]
            ? answers[condition.question].indexOf(condition.value) !== -1
            : false;
        }
        if (!blockCompliesToConditions) {
          continue;
        }
        if (blockCompliesToConditions) {
          fb.push(b);
        }
      } else {
        fb.push(b);
      }
    }

    let qs = [];
    for (let i in fb) {
      for (let j in fb[i].questions) {
        qs.push(fb[i].questions[j]);
      }
    }
    return qs;
  };

  const conditionForKey = (conditions, key) => {
    for (let i in conditions) {
      if (conditions[i].key === key) {
        return conditions[i];
      }
    }
    return null;
  };

  const updateQuestions = (questionOffset) => {
    let qs = questionsArray(group);

    setQuestions([...qs]);
    if (currentQuestion === qs.length - 1 && questionOffset > 0) {
      // last question
      setStage(FORM_STAGE.Complete);
    } else {
      setCurrentQuestion(currentQuestion + questionOffset);
    }
  };

  const previousQuestion = () => {
    updateQuestions(-1);
  };

  const isQuestionComplete = (question, answer) => {
    switch (question.type) {
      case "string":
        return !!answer && answer !== "";
      case "number":
        return !!answer && answer !== "";
      case "date":
        return !!answer;
      case "multiselect":
        return true;
      case "select":
        return true;
      default:
        return true;
    }
  };

  const saveDocument = async () => {
    // default document lifetime 30 days
    const lifetime = 1000 * 60 * 60 * 24 * 30;
    const complete = isDocumentComplete();
    startLoading("document", t("document_form.saving_doc"));
    let udg = {
      answers: answers,
      client: currentUserUid(),
      group: group.id,
      partner: partner.id,
      complete: complete,
    };
    if (!userDocumentGroup) {
      udg.expires = moment().valueOf() + lifetime;
    }

    if (complete) {
      udg.docs = {};
      for (let i in group.documents) {
        let doc = group.documents[i];
        let docRecord = {};
        docRecord.name = doc.name;
        docRecord.description = !!doc.description ? doc.description : "";
        docRecord.download_urls = {};
        docRecord.status = "empty";
        docRecord.id = doc.document_id;
        udg.docs[doc.document_id] = docRecord;
      }
    }

    let result = await updateUserDocumentGroup(
      udg,
      !!userDocumentGroup ? userDocumentGroup.id : null
    );
    if (result.error) {
      stopLoading("document");
      showDialog(t("general.error_title"), result.error.message, [
        {
          title: t("general.close"),
          onClick: () => {
            hideDialog();
          },
          contained: true,
        },
      ]);
      return;
    }
    stopLoading("document");

    if (complete) {
      let message;
      if (!udg || !udg.id) {
        message = t("document_form.created");
      } else {
        message = t("document_form.updated");
      }

      showDialog(
        t(`general.success_title`),
        message,
        [
          {
            title: t(`document_form.view_docs`, {}, partnerCopySuffix(partner)),
            onClick: () => {
              historyPush("/dashboard");
              hideDialog();
            },
            contained: true,
            type: "important",
          },
        ]
      );
    } else {
      showDialog(
        t(`general.success_title`, {}, partnerCopySuffix(partner)),
        t("document_form.draft_saved"),
        [
          {
            title: t("document_form.to_dashboard"),
            onClick: () => {
              historyPush("/dashboard");
              hideDialog();
            },
            contained: true,
            type: "important",
          },
          {
            title: t("general.continue"),
            onClick: () => {
              hideDialog();
            },
          },
        ]
      );
    }
  };

  const back = () => {
    history.goBack();
  };

  const selectLocale = (locale) => {
    updateUrlParams(history, { locale: locale });
  };

  const selectCategory = (category) => {
    updateUrlParams(history, { category: category.id });
  }

  const selectGroup = (group) => {
    historyPush(`/document/${group.id}`);
  };

  const renderLoading = () => {
    return <div></div>;
  };

  const renderLocale = () => {
    return (
      <div className="stretch column centered-container mt-4">
        <Grid
          justify="center"
          alignItems="center"
          container
          spacing={4}
          className="mt-4"
        >
          {locales.map((locale, i) => {
            return (
              <Grid item sm={3} key={`${locale}_button`}>
                <Card>
                  <CardContent className="language-card">
                    <img
                      className="flag-img"
                      src={`/assets/images/${locale
                        .split("_")[1]
                        .toLowerCase()}.png`}
                      alt={`${locale} flag`}
                    />
                    <span className="mt-4 primary-title">
                      {languageFromLocale(locale, !isPartnerSNI(partner))}
                    </span>
                    <Button
                      className="mt-4"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        selectLocale(locale);
                      }}
                    >
                      {t("general.select")}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  };

  const renderCategories = () => {
    const filteredCategories = []
    for(let i in categories) {
      if(categories[i].locale === locale) {
        filteredCategories.push(categories[i])
      }
    }
    return (
      <div className="stretch column centered-container mt-2">
        <Masonry
          breakpointCols={Math.min(2, filteredCategories.length)}
          className="my-masonry-grid mt-4"
          columnClassName="my-masonry-grid_column"
        >
          {filteredCategories.map((category, i) => {
            return (
              <GroupBlock
                key={`category_${i}`}
                group={category}
                onSelect={() => selectCategory(category)}
                buttonTitle={t("general.select")}
              />
            );
          })}
        </Masonry>
      </div>
    );
  };

  const renderGroups = () => {
    let filteredGroups = [];
    for (let i in documentGroups) {
      if (documentGroups[i].locale !== locale) {
        continue
      }
      if(!((categories.find(cat => cat.id === category) || {}).groups || []).includes(documentGroups[i].id)) {
        continue
      }
      filteredGroups.push(documentGroups[i]);
    }
    return (
      <div className="stretch column centered-container mt-2">
        <Masonry
          breakpointCols={Math.min(2, filteredGroups.length)}
          className="my-masonry-grid mt-4"
          columnClassName="my-masonry-grid_column"
        >
          {filteredGroups.map((group, i) => {
            return (
              <GroupBlock
                key={`group_${i}`}
                group={group}
                onSelect={() => selectGroup(group)}
                buttonTitle={t("general.select")}
              />
            );
          })}
        </Masonry>
      </div>
    );
  };

  const isDocumentComplete = () => {
    if (questions.length === 0 || currentQuestion === -1) {
      return false;
    }
    for (let i in questions) {
      if (!isQuestionComplete(questions[i], answers[questions[i].key])) {
        return false;
      }
    }
    return true;
  };

  const currentProgress = () => {
    return currentQuestion / questions.length;
  };

  const renderQuestions = () => {
    if (questions.length === 0 || currentQuestion === -1) {
      return <div />;
    }
    return (
      <div className="question-container">
        <div className="stretch column question-wrapper">
          <div className="mt-4">
            <Collapse in={stage === FORM_STAGE.Questions}>
              {renderQuestion(questions[currentQuestion])}
            </Collapse>
            <Collapse in={stage === FORM_STAGE.Complete}>
              {renderComplete()}
            </Collapse>
          </div>
          {stage === FORM_STAGE.Questions ? renderFooter() : null}
        </div>
      </div>
    );
  };

  const renderComplete = () => {
    return (
      <div className="stretch column centered mt-4">
        <span>
          <h4>{t("document_form.complete_title")}</h4>
          <br />
          <h5>{t("document_form.complete_message")}</h5>
        </span>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          className="mt-4"
        >
          <Grid item>
            <Button
              className="mt-2"
              color="primary"
              onClick={() => setStage(FORM_STAGE.Questions)}
            >
              {t("document_form.review")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="custom-button green"
              startIcon={<SaveIcon />}
              onClick={saveDocument}
              variant="contained"
              color="primary"
            >
              {t("document_form.generate")}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="document-form-footer question-buttons">
        <Button
          onClick={saveDocument}
          className={`custom-button mr-3 ${
            isDocumentComplete() ? "green" : "white"
          }`}
        >
          {t(`general.save`, {}, partnerCopySuffix(partner))}
        </Button>
        <div className="form-progress-container">
          <span className="progress-label">
            {t("document_form.progress", {
              progress: (currentProgress() * 100).toFixed(0),
            })}
          </span>
          <LinearProgress
            className="mt-1 rounded"
            variant="determinate"
            value={currentProgress() * 100}
          />
        </div>
        <ButtonGroup
          className="ml-3 custom-button primary-dark"
          color="primary"
          variant="contained"
          aria-label="split button"
          size="small"
        >
          <IconButton
            onClick={previousQuestion}
            disabled={currentQuestion === 0}
            className="custom-button primary-dark"
          >
            <ExpandLessIcon />
          </IconButton>
          <IconButton
            onClick={submitQuestion}
            className="custom-button primary-dark"
          >
            <ExpandMoreIcon />
          </IconButton>
        </ButtonGroup>
      </div>
    );
  };

  const renderQuestion = (question) => {
    if (!!question) {
      return (
        <Question
          key={`question_${question.key}`}
          question={question}
          onChange={(value) => onAnswerChanged(value, question.key)}
          onSubmit={submitQuestion}
          answer={answers[question.key]}
        />
      );
    } else {
      return <div />;
    }
  };

  const renderNavbar = () => {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className="mt-4"
      >
        <Grid item sm={2}>
          {/* <Button
            startIcon={<ArrowBackIcon />}
            className="simple-button gray"
            onClick={back}
          >
            {t("general.back")}
          </Button> */}
        </Grid>
        <Grid item sm={6} className="centered">
          <h5 className="primary-title">
            {stage === FORM_STAGE.Language
              ? t("document_form.select_language")
              : stage === FORM_STAGE.Category
              ? t("document_form.select_category", {})
              : stage === FORM_STAGE.Group
              ? t("document_form.select_group", {}, partnerCopySuffix(partner))
              : stage === FORM_STAGE.Questions
              ? !!group
                ? group.name
                : ""
              : ""}
          </h5>
          {isPartnerSNI(partner) && stage === FORM_STAGE.Group ? (
            <p className="sub-para document-block-doc-title ">
              {t("document_form.select_title__sni")}
            </p>
          ) : null}
        </Grid>
        <Grid item sm={2} />
      </Grid>
    );
  };

  return (
    <Container maxWidth="md">
      {stage === FORM_STAGE.Loading ? (
        renderLoading()
      ) : (
        <div className="stretch column content-page">
          {renderNavbar()}
          {stage === FORM_STAGE.Language || stage === FORM_STAGE.Group || stage === FORM_STAGE.Category ? (
            <Button
              startIcon={<ArrowBackIcon />}
              className="simple-button gray back-button mt-4"
              onClick={back}
            >
              {t("general.back")}
            </Button>
          ) : null}
          <Collapse in={stage === FORM_STAGE.Language}>
            {renderLocale()}
          </Collapse>
          <Collapse in={stage === FORM_STAGE.Category}>{renderCategories()}</Collapse>
          <Collapse in={stage === FORM_STAGE.Group}>{renderGroups()}</Collapse>
          <Collapse
            in={stage === FORM_STAGE.Questions || stage === FORM_STAGE.Complete}
          >
            {renderQuestions()}
          </Collapse>
        </div>
      )}
    </Container>
  );
};

export default DocumentForm;

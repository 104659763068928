import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container'
import Masonry from 'react-masonry-css'
import { GroupBlock } from '../components'
import { sortedArrayFromObject, languageCodeFromLocale } from '../utils'


import i18n from '../utils/i18n'
const t = i18n.instance().t
const getLanguage = i18n.instance().getLanguage

const GetStarted = (props) => {

  const { historyPush, historyReplace, userData } = props

  const [recommended, setRecommended] = useState([])
  const [others, setOthers] = useState([])


  useEffect(() => {
    let grps = sortedArrayFromObject(props.documentGroups, 'id', true)
    let fGrps = []

    let lang = getLanguage()

    for(let i in grps) {
      if(languageCodeFromLocale(grps[i].locale) === lang) {
        fGrps.push(grps[i])
      }
    }
    let rec = []
    let oth = []
    for(let i in fGrps) {
      if(shouldRecommend(fGrps[i])) {
        rec.push(fGrps[i])
      } else {
        oth.push(fGrps[i])
      }
    }
    setRecommended([...rec])
    setOthers([...oth])
  }, [props.documentGroups, i18n.instance().language, props.userData])


  // { key: 'client_all', label: 'Client has website & employees' },
  // { key: 'client_employees', label: 'Client has employees' },
  // { key: 'client_website', label: 'Client has website' },
  // { key: 'client_empty', label: "Client doesn't have employees or website" },
  // { key: 'none', label: "Don't use for recommendations"}

  const shouldRecommend = (group) => {
    if(!userData) {
      return group.recommendation_type === 'client_empty'
    }
    if(group.recommendation_type === 'always') {
      return true
    } else if(group.recommendation_type === 'client_all') {
      return (!!userData.contact && !!userData.contact.website && !!userData.enterprise && !!userData.enterprise.employees)
    } else if(group.recommendation_type === 'client_employees') {
      return (!!userData.enterprise && !!userData.enterprise.employees)
    } else if(group.recommendation_type === 'client_website') {
      return (!!userData.contact && !!userData.contact.website)
    } else if(group.recommendation_type === 'client_empty') {
      return ((!userData.contact || !userData.contact.website) && (!userData.enterprise || !userData.enterprise.employees))
    } else {
      return false
    }
  }

  const renderHeader = () => {
    return <div className="stretch column centered mt-5 mb-5">
      <span className="onboarding-title">
        { t('get_started.title') }
      </span>
      <span className="onboarding-subtitle mt-3">
        { t('get_started.subtitle') }
      </span>
    </div>
  }

  const selectGroup = (group) => {
    historyReplace('/dashboard')
    historyPush(`/document/${group.id}`)
  }

  const renderGroups = (groups) => {
    return (
      <div className="stretch column centered-container mt-2">
        <Masonry
          breakpointCols={Math.min(2, groups.length)}
          className="my-masonry-grid mt-4"
          columnClassName="my-masonry-grid_column">
            { groups.map((group, i) => {
            return (
              <GroupBlock
                key={`group_${i}`}
                group={group}
                buttonTitle={t('document_block.generate_document')}
                onSelect={() => selectGroup(group) } />
            )
          }) }
        </Masonry>
      </div>
    )
  }

  const renderSeparator = () => {
    return (
      <div className="stretch mt-4 mb-4 get-started-separator">
        <div className="get-started-separator-line" />
        <span className="get-started-separator-label">{ t('get_started.other') }</span>
        <div className="get-started-separator-line" />
      </div>
    )
  }

  return (
    <Container maxWidth="md" className="mb-4">
      { renderHeader() }
      { renderGroups(recommended) }
      { renderSeparator() }
      { renderGroups(others) }
    </Container>
  )
}

export default GetStarted
import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import i18n from '../utils/i18n'
import { Collapse } from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
const t = i18n.instance().t

const GroupBlock = (props) => {

  const { group, buttonTitle, onSelect } = props

  const [expanded, setExpanded] = useState(false)

  const toggle = () => {
    setExpanded(!expanded)
  }

  return (
    <Card className="group-block">
      <img className="group-block-image" src={group.image} alt="group cover" />
      <div className="group-block-icon-wrapper">
        <img className="group-block-icon" src={group.icon} alt="group icon" />
      </div>
      <div className="group-block-content">
        <h4 className="group-block-title">{ group.name }</h4>
        { !!group.description ? (
          <div>
            <Collapse in={expanded}>
              <div className="mt-2">
                <span className="group-block-description light-weight">{group.description}</span>
              </div>
            </Collapse>
            <div className="group-expand-footer">
              <Button className="group-expand-button" onClick={toggle} startIcon={expanded ? <ArrowRightAltIcon className="flipped" /> : null } endIcon={expanded ? null : <ArrowRightAltIcon /> }>{ expanded ? t('document_form.show_less') : t('document_form.show_more') }</Button>
            </div>
          </div>
        ) : null }
        <Button className="mt-2 stretch" color="primary" variant="contained" onClick={onSelect}>{ buttonTitle }</Button>
      </div>
    </Card>
  )
}

export default GroupBlock
import React, { useEffect, useRef } from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { t } from '../utils'
import _ from 'lodash'
import moment from 'moment'

const Question = (props) => {

  const { question, answer, onChange, onSubmit } = props

  const textInput = useRef(null)

  useEffect(() => {
    const handler = (event) => {
      if(event.keyCode === 13) {
        onSubmit()
      }
    }

    window.addEventListener("keydown", handler)

    return () => window.removeEventListener("keydown", handler);
  }, [question, answer])

  useEffect(() => {
    if(!!textInput && !!textInput.current) {
      textInput.current.focus()
    }
  }, [textInput])


  const renderTextInput = () => {
    return(
      <TextField
        className="stretch"
        ref={textInput}
        autoFocus={true}
        type="text" value={answer} onChange={(t) => onChange(t.target.value) } />
    )
  }

  const renderNumberInput = () => {
    return (
      <TextField
        className="stretch"
        ref={textInput}
        autoFocus={true}
        value={answer}
        onChange={(t) => onChange(t.target.value) }
        type="number"
      />
    )
  }

  const renderDateInput = () => {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          className="stretch"
          autoOk
          variant="inline"
          emptyLabel={t('document_form.select_date')}
          format="DD/MM/YYYY"
          value={!!answer ? moment(answer) : null}
          onChange={date => onChange(date.valueOf())}
        />
      </MuiPickersUtilsProvider>
    )
  }

  const handleMultiselectOptionChange = (option, checked) => {
    let a = [...answer]
    if(checked && a.indexOf(option) === -1) {
      a.push(option)
    } else if(!checked && a.indexOf(option) !== -1) {
      a.splice(a.indexOf(option), 1)
    }
    onChange(a)
  }

  const renderMultiselectInput = (question) => {
    return (
        <FormControl
          className="stretch" component="fieldset">
          <FormGroup>
            { question.options.map((option, oi) => {
              return (
                <FormControlLabel
                  key={`option_${oi}`}
                  control={
                    <Checkbox
                      color="primary"
                      checked={answer.indexOf(oi) !== -1}
                      onChange={ e => handleMultiselectOptionChange(oi, e.target.checked) }
                    />}
                  label={option}
                />
              )
            })}
          </FormGroup>
        </FormControl>
    )
  }

  const renderSelectInput = (question) => {
    return (
      <FormControl
        className="stretch" component="fieldset">
        <RadioGroup
          value={answer}
          onChange={e => onChange(parseInt(e.target.value))}>
          { question.options.map((option, oi) => {
            return (
              <FormControlLabel
                key={`option_${oi}`}
                value={oi}
                control={ <Radio color="primary" />}
                label={option}
              />
            )
          })}
        </RadioGroup>
      </FormControl>
    )
  }

  const renderInput = (question) => {
    switch(question.type) {
      case 'string':
        return renderTextInput()
      case 'number':
          return renderNumberInput()
      case 'date':
        return renderDateInput()
      case 'multiselect':
        return renderMultiselectInput(question)
      case 'select':
        return renderSelectInput(question)
    }
  }

  if(!!question) {
    return (
      <div className="stretch column mt-4">
        <h4 className="question-label">{ question.text }</h4>
        { !_.isEmpty(question.description) ? (
          <h6 className="question-label mt-2">{ question.description }</h6>
        ) : null }
        <div className="mt-2 stretch">
          { renderInput(question) }
        </div>
      </div>
    )
  } else {
    return (
      <div></div>
    )
  }
}

export default Question
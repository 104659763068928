import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import { t } from '../utils'

import cookieIcon from '../assets/cookie_sni.png'

const CookiesSection = (props) => {
  const { partner, shouldAcceptCookies, currentLanguage } = props
  const [showPreferences, setShowPreferences] = useState(false)
  const [trackingCheck, setTrackingCheck] = useState(false)
  // const [langCheck, setLangCheck] = useState(false)

  const savePreferencesHandler = (e) => {
    e.preventDefault()
    shouldAcceptCookies({ required: true, tracking: trackingCheck })
    // shouldAcceptCookies({ required: true, tracking: trackingCheck, lang: langCheck })
  }

  const saveRequiredCookies = (e) => {
    e.preventDefault()
    shouldAcceptCookies({ required: true, tracking: false })
    // shouldAcceptCookies({required: true, tracking: false, lang: false})
  }

  const saveAllCookies = (e) => {
    e.preventDefault()
    shouldAcceptCookies({ required: true, tracking: true })
    // shouldAcceptCookies({required: true, tracking: true, lang: true})
  }

  const showPreferencesModal = (e) => {
    e.preventDefault()
    setShowPreferences(true)
  }

  return (
    <div className="cookies-section-wrapper">
      <div className="container">
        <div className="cookies-section">
          <img src={cookieIcon} alt="" className="cookies-section__cookie" />
          <div className="cookies-section__top">
            <p>{t('cookies.intro')} {t('cookies.discription')}</p>
          </div>
          <div className="cookies-section__actions">
            <a href="#" className="blue" onClick={saveRequiredCookies}>{t('cookies.toggle_1')}</a>
            <a href="#" className="" onClick={saveAllCookies}>{t('cookies.toggle_2')}</a>
            <a href="#" className="" onClick={showPreferencesModal}>{t('cookies.toggle_3')}</a>
          </div>
          <div className="cookies-section__bottom">
            <p>
              {t('cookies.detail_text')}&nbsp;
              <Link to={partner ? `/${partner?.url}/${currentLanguage}/cookie-policy` : '#'} className="link">{t('cookies.detail_text_1')}</Link>
            </p>
          </div>
          {showPreferences && <Dialog
              scroll="body"
              maxWidth="lg"
              onClose={() => setShowPreferences(false)}
              open={showPreferences}>
                <Fragment>
                  <div className="configure-body configure-body--cookies-section">
                    <h5>{t('cookies.toggle_3')}</h5>
                    <div className="configure">
                      <div className="cookie__field">
                        <div className="cookie__text cookie__text--light">
                          {t('cookies.toggle_4')}
                        </div>
                        <label className="switch input-inline-block">
                          <input className="input_toogle" type="checkbox" checked />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="configure__desc">{t('cookies.discription_toggle_4')}</div>
                    </div>
                    <div className="configure">
                      <div className="cookie__field">
                        <div className="cookie__text cookie__text--light">
                          {t('cookies.toggle_5')}
                        </div>
                        <label className="switch input-inline-block">
                          <input className="input_toogle" type="checkbox" value={trackingCheck} onChange={() => setTrackingCheck(!trackingCheck)} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="configure__desc">{t('cookies.discription_toggle_5')}</div>
                    </div>
                    {/* <div className="configure">
                      <div className="cookie__field">
                        <div className="cookie__text cookie__text--light">
                          {t('cookies.toggle_6')}
                        </div>
                        <label className="switch input-inline-block">
                          <input className="input_toogle" type="checkbox" value={langCheck} onChange={() => setLangCheck(!langCheck)} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="configure__desc">{t('cookies.discription_toggle_6')}</div>
                    </div> */}
                    <a href="#" className="blue" onClick={savePreferencesHandler}>{t('cookies.save')}</a>
                  </div>
                </Fragment>
            </Dialog>}
        </div>
      </div>
    </div>
  )
}

export default CookiesSection
import React, { useState, useEffect, useRef } from 'react'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio';
import LinearProgress from '@material-ui/core/LinearProgress';

import { ProfileWrapper } from '../components'

import i18n from '../utils/i18n'
import { updateUserData } from '../services/database'
import { enterpriseTypeOptionsForPartner, isPartnerFROnly, partnerCopySuffix } from '../utils'
const t = i18n.instance().t

const requiredFields = [
  'title',
  'name_first',
  'name_last',
  // 'occupation',
  'enterprise',
  'enterprise_type',
  'address_street',
  // 'address_number',
  'address_zip',
  'address_city',
  'phone',
  'email'
]

const defaultData = {
  title: '',
  name_first: '',
  name_last: '',
  // occupation: '',
  enterprise: '',
  enterprise_type: '',
  address_city: '',
  // address_number: '',
  address_street: '',
  address_zip: '',
  enterprise_number: '',
  enterprise_contact_phone: '',
  enterprise_contact_email: '',
  enterprise_contact_position: '',
  vat_number: '',
  email: '',
  phone: '',
  website: '',
  logo: '',
  logoData: {
    data: null,
    format: ''
  },
  employees: 1
}

const title_options = ['mr', 'ms']

const employee_options = ['yes', 'no']

const Onboarding = (props) => {

  const { startLoading, stopLoading, showError, partner, historyReplace } = props

  const [data, setData] = useState({...defaultData})

  const fileInput = useRef(null)

  const enterprise_type_options = enterpriseTypeOptionsForPartner(partner)

  useEffect(() => {
    setupData(props.userData)
  }, [props.userData])

  const setupData = (userData) => {
    if(!userData) {
      setData({...defaultData})
      return
    }
    setData({
      title: !!userData.title ? userData.title : '',
      name_first: !!userData.name ? userData.name.first : '',
      name_last: !!userData.name ? userData.name.last : '',
      // occupation: userData.occupation,
      enterprise: !!userData.enterprise ? userData.enterprise.name : '',
      enterprise_type: !!userData.enterprise ? userData.enterprise.type : '',
      enterprise_number: !!userData.enterprise ? userData.enterprise.enterprise_number : '',
      enterprise_contact_phone: !!userData.enterprise
        ? userData.enterprise.contact_phone ? userData.enterprise.contact_phone : ""
        : "",
      enterprise_contact_email: !!userData.enterprise
        ? userData.enterprise.contact_email ? userData.enterprise.contact_email : ""
        : "",
      enterprise_contact_position: !!userData.enterprise
        ? userData.enterprise.contact_position ? userData.enterprise.contact_position : ""
        : "",
      address_street: !!userData.address ? userData.address.street : '',
      // address_number: !!userData.address ? userData.address.number : '',
      address_zip: !!userData.address ? userData.address.zip : '',
      address_city: !!userData.address ? userData.address.city : '',
      vat_number: userData.vat_number,
      email: !!userData.contact ? userData.contact.email : '',
      phone: !!userData.contact ? userData.contact.phone : '',
      website: !!userData.contact ? userData.contact.website : '',
      logo: userData.logo,
      employees: !!userData.enterprise && !!userData.enterprise.employees ? userData.enterprise.employees ? 0 : 1 : 1
    })
  }

  const onDataChange = (value, handle) => {
    let d = data
    d[handle] = value
    setData({...d})
  }

  const editLogo = () => {
    if(!!fileInput) {
      fileInput.current.click()
    }
  }

  const onImageSubmitted = (e) => {
    let files = e.target.files
    const components = files[0].name.split('.')
    const format = components[components.length - 1]
    if (files && files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        let d = data
        d.logoData = {
          data: e.target.result,
          format: format
        }
        setData({...data})
        if(!!fileInput && !!fileInput.current) {
          fileInput.current.value = ""
        }
      }
      reader.onerror = (err) => {
        console.log('reader on error', err)
      }
      reader.readAsDataURL(files[0]);
    }
  }

  const save = async () => {
    let mf = missingField()
    if(!!mf) {
      showError(t('profile.data_incomplete', { field: labelForField(mf) }))
      return
    }
    startLoading('profile', t('general.update'))
    const {
      title,
      name_first,
      name_last,
      // occupation,
      enterprise,
      enterprise_type,
      address_city,
      // address_number,
      address_street,
      address_zip,
      enterprise_number,
      enterprise_contact_email,
      enterprise_contact_phone,
      enterprise_contact_position,
      vat_number,
      email,
      phone,
      logoData,
      website,
      employees
    } = data

    let profileData = {
      title: title,
      name: {
        first: name_first,
        last: name_last
      },
      // occupation,
      enterprise: {
        name: enterprise,
        type: enterprise_type,
        employees: employees === 0,
        enterprise_number: enterprise_number,
        contact_phone: enterprise_contact_phone,
        contact_email: enterprise_contact_email,
        contact_position: enterprise_contact_position,
      },
      address: {
        street: address_street,
        // number: address_number,
        zip: address_zip,
        city: address_city
      },
      contact: {
        email: email,
        phone: phone
      }
    }
    if(!!enterprise_number) {
      profileData.enterprise.enterprise_number = enterprise_number
    }
    if(!!vat_number) {
      profileData.vat_number = vat_number
    }
    if(!!website) {
      profileData.contact.website = website
    }

    await updateUserData(profileData, logoData)
    stopLoading('profile')
    historyReplace('/get-started')
  }

  const missingField = () => {
    for(let i in requiredFields) {
      let value = data[requiredFields[i]]
      if(!value || value === '') {
        return requiredFields[i]
      }
    }
    return null
  }

  const completeCount = (fields) => {
    let complete = 0
    for(let i in fields) {
      let value = data[fields[i]]
      if(!!value && value !== '') {
        complete++
      }
    }
    return complete
  }

  const skip = () => {
    historyReplace('/dashboard')
  }

  const renderHeader = () => {
    return <div className="stretch column centered mt-5 mb-5">
      <Button className="plain-button onboarding-skip" onClick={skip}>{ t('onboarding.skip') }</Button>
      <span className="onboarding-title">
        { t('onboarding.title') }
      </span>
      <span className="onboarding-subtitle mt-3">
        { t('onboarding.subtitle') }
      </span>
    </div>
  }

  const renderProgress = () => {
    return (
      <div className="stretch column">
        <div className="onboarding-progress-container">
          <span className="onboarding-progress-label">{ t('onboarding.your_info') }</span>
          <span className="onboarding-progress-label">{ `${completeCount(requiredFields)}/${requiredFields.length}` }</span>
        </div>
        <LinearProgress className="rounded green" variant="determinate" value={completeCount(requiredFields)/requiredFields.length*100} />
      </div>
    )
  }

  const renderProfile = () => {
    return (
      <ProfileWrapper className="mt-4" title={t('onboarding.profile')} progress={{ show: true, items: 3, complete: completeCount(['title', 'name_first', 'name_last' ])}}>
        <div className="stretch column">
          <div className="half">
            <FormControl
              className="stretch">
              <InputLabel htmlFor="title">{ labelForField('title') }</InputLabel>
              <Select
                value={data.title}
                onChange={(t) => { onDataChange(t.target.value, 'title') }}
                inputProps={{
                  name: 'title',
                  id: 'title',
                }}
              >
                { title_options.map((option, i) => {
                  return <MenuItem key={`title_option_${i}`} value={option}>{ t(`profile.field_title_${option}`) }</MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
          <TextField
            margin="normal"
            className="stretch"
            label={labelForField('name_last')}
            value={data.name_last}
            onChange={(t) => onDataChange(t.target.value, 'name_last')}
          />
          <TextField
            margin="normal"
            className="stretch"
            label={labelForField('name_first')}
            value={data.name_first}
            onChange={(t) => onDataChange(t.target.value, 'name_first')}
          />
        </div>
      </ProfileWrapper>
    )
  }

  const renderEnterpriseNumber = () => {
    return(
      <ProfileWrapper className="mt-4" title = {t("onboarding.enterprise_number", {}, isPartnerFROnly(partner) ? '__fr' : '')}>
          <TextField

            className="stretch"
            label={labelForField('enterprise_number')}
            type="text"
            value={data.enterprise_number}
            onChange={(t) => onDataChange(t.target.value, 'enterprise_number')}
          />
      </ProfileWrapper>
    )
  }

  const renderEnterprise = () => {
    return (
      <ProfileWrapper className="mt-4" title={t('profile.enterprise_title')} progress={{ show: true, items: 2, complete: completeCount(['enterprise', 'enterprise_type' ])}}>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <div className="logo-container">
              <Card className="edit-image">
                <IconButton color="primary" onClick={editLogo}>
                  <EditIcon fontSize="small" />


                </IconButton>
              </Card>
              <img className="logo" src={!!data.logoData && !!data.logoData.data ? data.logoData.data : !!data.logo ? data.logo : '/assets/images/logo_placeholder.png' } />
            </div>
          </Grid>
          <Grid item sm={8}>
            <div className="stretch column">
              <TextField
                className="stretch"
                label={labelForField('enterprise') }
                value={data.enterprise}
                onChange={(t) => onDataChange(t.target.value, 'enterprise')}
              />
              <FormControl
                margin="normal"
                className="stretch">
                <InputLabel htmlFor="enterprise_type">{ labelForField('enterprise_type') }</InputLabel>
                <Select
                  value={data.enterprise_type}
                  onChange={(t) => { onDataChange(t.target.value, 'enterprise_type') }}
                  inputProps={{
                    name: 'enterprise_type',
                    id: 'enterprise_type',
                  }}
                >
                  { enterprise_type_options.map((option, i) => {
                    return <MenuItem key={`title_option_${i}`} value={option}>{ t(`profile.field_enterprise_type_${option}`) }</MenuItem>
                  })}
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
        <div className="stretch column">
          <TextField
            margin="normal"
            className="stretch"
            label={labelForField('website')}
            value={data.website}
            onChange={(t) => onDataChange(t.target.value, 'website')}
          />
          <FormControl
            className="stretch">
            <InputLabel disableAnimation shrink={true}>{ labelForField('enterprise_employees') }</InputLabel>
            <RadioGroup
              className="mt-3"
              row
              value={data.employees}
              onChange={e => onDataChange(parseInt(e.target.value), 'employees') }>
              { employee_options.map((option, oi) => {
                return (
                  <FormControlLabel
                    key={`option_${oi}`}
                    value={oi}
                    control={ <Radio size="small" color="primary" />}
                    label={t(`general.${option}`)}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
        </div>
      </ProfileWrapper>
    )
  }

  const renderEnterpriseDetail = () => {
    return (
      <ProfileWrapper className="mt-4" title={t('profile.enterprise_detail_title')} progress={{ show: true, items: 5, complete: completeCount(['address_street', 'address_city', 'address_zip', 'phone', 'email' ])}}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <TextField
              className="stretch"
              label={labelForField('address_street')}
              value={data.address_street}
              onChange={(t) => onDataChange(t.target.value, 'address_street')}
            />
          </Grid>
          {/* <Grid item sm={4}>
            <TextField
              className="stretch"
              label={labelForField('address_number')}
              value={data.address_number}
              type="number"
              onChange={(t) => onDataChange(t.target.value, 'address_number')}
            />
          </Grid> */}
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <TextField
              margin="normal"
              className="stretch"
              label={labelForField('address_city')}
              value={data.address_city}
              onChange={(t) => onDataChange(t.target.value, 'address_city')}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              margin="normal"
              className="stretch"
              label={labelForField('address_zip')}
              value={data.address_zip}
              onChange={(t) => onDataChange(t.target.value, 'address_zip')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <TextField
              margin="normal"
              className="stretch"
              label={labelForField('vat_number')}
              type="text"
              value={data.vat_number}
              onChange={(t) => onDataChange(t.target.value, 'vat_number')}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              margin="normal"
              className="stretch"
              label={labelForField('phone') }
              type="text"
              value={data.phone}
              onChange={(t) => onDataChange(t.target.value, 'phone')}
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <TextField
            margin="normal"
            className="stretch"
            label={labelForField('email') }
            value={data.email}
            onChange={(t) => onDataChange(t.target.value, 'email')}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <TextField
              margin="normal"
              className="stretch"
              label={labelForField("enterprise_contact_phone")}
              value={data.enterprise_contact_phone}
              onChange={(t) =>
                onDataChange(t.target.value, "enterprise_contact_phone")
              }
              type="text"
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              margin="normal"
              className="stretch"
              type="email"
              label={labelForField("enterprise_contact_email")}
              value={data.enterprise_contact_email}
              onChange={(t) =>
                onDataChange(t.target.value, "enterprise_contact_email")
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <TextField
              className="stretch"
              label={labelForField("enterprise_contact_position")}
              value={data.enterprise_contact_position}
              onChange={(t) =>
                onDataChange(t.target.value, "enterprise_contact_position")
              }
            />
          </Grid>
        </Grid>
      </ProfileWrapper>
    )
  }


  return (
    <Container maxWidth="sm" className="mb-4 pb-4">
      { renderHeader() }
      { renderProgress() }
      { renderProfile() }
      { renderEnterpriseNumber() }
      { renderEnterprise() }
      { renderEnterpriseDetail() }
      {/* footer */}
      <Grid container row justify="center" className="mt-4 mb-4">
        <Grid item>
          <Button variant="contained" color="primary" size="large" onClick={save}>{ t('general.save') }</Button>
        </Grid>
      </Grid>
      <input id="fileInput" ref={fileInput} hidden type='file' onChange={onImageSubmitted} accept="image/png,image/jpg" />
    </Container>
  )
}

const labelForField = (field) => {
  let isRequired = requiredFields.indexOf(field) != -1
  let label = t(`profile.field_${field}`)
  return `${label}${isRequired ? ' *' : ''}`
}

export default Onboarding
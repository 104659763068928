import React from 'react'
import Grid from '@material-ui/core/Grid'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const ProfileWrapper = (props) => {

  const { progress, title, children } = props

  return (
    <div className={`${props.className} profile-wrapper ${!!progress && progress.show && progress.complete === progress.items ? 'complete' : ''}`}>
      <Grid container direction="row" justify="space-between" alignItems="center" className="profile-wrapper-header">
        <Grid item className="row">
          <h4 className="profile-wrapper-title">{ title }</h4>
          { !!progress && progress.show ? <span className="profile-wrapper-completion ml-3">{ `${progress.complete}/${progress.items}` }</span> : null }
        </Grid>
        <Grid item>
          { !!progress && progress.show ? progress.complete < progress.items ? <WarningIcon color="secondary" /> : <CheckCircleIcon color="primary" /> : null }
        </Grid>
      </Grid>
      <div className="profile-wrapper-content">
        { children }
      </div>
    </div>
  )
}

export default ProfileWrapper
import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import cookie from 'react-cookies';
import "./App.css";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { Router } from "react-router-dom";
import history from "./services/history";
import Routes, {
  defaultUnauthorizedPath,
  isPathPrivate,
} from "./routes";

import { PageContainer } from "./modules";

import { Dialog, Loading, CookiesSection } from "./components";

import { createUserStateChangedListener, signOut } from "./services/auth";

import * as database from "./services/database";
import { acceptCookies, cookiesAccepted, GA_ID } from './utils';

import i18n from "./utils/i18n";

const t = i18n.instance().t;
const setLanguage = i18n.instance().setLanguage;

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#064b8d",
      contrastText: "#fff",
    },
    secondary: {
      main: "#f00",
      contrastText: "#fff",
    },
    warning: {
      main: "#ff9d26",
      contrastText: "#fff",
    },
    success: {
      main: "#4caf4f",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: [
      "Open Sans",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      fontWeight: "600",
      textTransform: "none",
    },
  },
  overrides: {
    MuiCardContent: {
      root: {
        // padding: 0,
        "&:last-child": {
          paddingBottom: 0,
        },
      },
    },
  },
});

const defaultSnackbar = {
  open: false,
  text: "",
  color: "info",
};

function App() {
  const [authStatusFound, setAuthStatusFound] = useState(false);

  const [snackbar, setSnackbar] = useState(defaultSnackbar);

  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState({});

  const [selectedLanguage, setSelectedLanguage] = useState("fr");
  const [languages, setLanguages] = useState(["fr"]);

  const [dialog, setDialog] = useState({
    title: "",
    message: "",
    buttons: [],
    visible: false,
  });

  const [listeners, setListeners] = useState({});

  const [documentGroups, setDocumentGroups] = useState({});
  const [categories, setCategories] = useState({})
  const [partner, setPartner] = useState(null);
  const [userDocumentGroups, setUserDocumentGroups] = useState({});
  const [userData, setUserData] = useState({});

  const [noPartner, setNoPartner] = useState(false)
  const [cookiesAcc, setCookiesAcc] = useState(false)
  const [showTrackingCode, setShowTrackingCode] = useState(false)

  useEffect(() => {
    if(cookiesAccepted()) {
      if(cookiesAccepted().tracking) {
        setShowTrackingCode(true)
      }else {
        removeGaCookies()
      }
    }else {
      setShowTrackingCode(false)
      removeGaCookies()
    }
  }, [cookiesAccepted, cookiesAcc])

  const removeGaCookies = () => {
    if(cookie.load(`_ga_${GA_ID}`)) {
      cookie.remove(`_ga_${GA_ID}`, { path: '/', domain: window.location.hostname })
    }
    if(cookie.load(`_ga`)) {
      cookie.remove(`_ga`, { path: '/', domain: window.location.hostname })
    }
  }

  const subscribeListeners = () => {
    startLoading("userDocumentGroups");
    startLoading("userData");
    let ls = listeners;
    if (!listeners.userDocumentGroups) {
      let listener = database.listenForUserDocumentGroups(
        partner.id,
        (data) => {
          stopLoading("userDocumentGroups");
          setUserDocumentGroups(data);
        }
      );
      ls.userDocumentGroups = listener;
    }
    if (!listeners.userData) {
      let listener = database.listenForUserData((data) => {
        setUserData(data);
        stopLoading("userData");
      });
      ls.userData = listener;
    }

    setListeners({ ...ls });
  };

  const unsubscribeListeners = () => {
    let ls = listeners;
    for (let key in ls) {
      if (key !== "user") {
        // keep user state listener
        if (!!ls[key]) {
          ls[key](); // unsubscribes that listener
        }
        ls[key] = null;
      }
    }
    setListeners({ ...ls });
  };

  const fetchStaticData = () => {
    startLoading("documentGroups");
    startLoading("categories");
    database.fetchDocumentGroups(partner).then(dg => {
      setDocumentGroups({ ...dg });
      stopLoading("documentGroups");
    });
    database.fetchCategories(partner).then(cat => {
      setCategories({ ...cat })
      stopLoading("categories")
    })
  };

  const fetchPartner = async (url) => {
    if (url === "__") {
      setPartner({});
    }
    let p = await database.fetchPartnerWithUrl(url);
    if (!p) {
      setNoPartner(true)
      setLoading(false)
    } else {
      let path = history.location.pathname;
      let components = path.split(["/"]);
      let defaultLanguage = "fr";
      let lang = components[2];
      let langs = languagesFromLocales(p.locales);
      if (langs.indexOf(lang) === -1) {
        // unspecified or unknown language
        lang = defaultLanguage;
      }
      setLanguages([...langs]);
      setSelectedLanguage(lang);
      setLanguage(lang);
      setPartner(p);
    }
  };

  const languagesFromLocales = (loc) => {
    let lang = [];
    for (let i in loc) {
      let l = loc[i].split("_")[0];
      if (lang.indexOf(l) === -1) {
        lang.push(l);
      }
    }
    return lang;
  };

  useEffect(() => {
    if (!!partner) {
      setupAuth();
    }
  }, [partner]);

  const setupAuth = () => {
    let ls = listeners;
    if (!ls.user) {
      let listener = createUserStateChangedListener(async (user) => {
        let path = history.location.pathname;
        setAuthStatusFound(true);
        if (!!user) {
          setEmail(user.email);
          // if(!isPathPrivate(path)) {
          //   history.push(`/${partner.url}${defaultAuthorizedPath}`)
          // }
          await fetchStaticData();
          subscribeListeners();
        } else {
          unsubscribeListeners();
          setEmail("");
          if (isPathPrivate(path)) {
            historyPush(defaultUnauthorizedPath);
          }
        }
      });
      ls.user = listener;
    } else {
      // already got user state listener
    }
    setListeners({ ...ls });
  };

  useEffect(() => {
    let path = history.location.pathname;
    let components = path.split(["/"]);
    let partnerUrl = components[1];
    fetchPartner(partnerUrl);
    setCookiesAcc(cookiesAccepted())
  }, []);

  const showDialog = (title, message, buttons) => {
    setDialog({
      title: title,
      message: message,
      buttons: buttons,
      visible: true,
    });
  };

  const hideDialog = () => {
    setDialog({
      title: "",
      message: "",
      buttons: [],
      visible: false,
    });
  };

  const showError = (message) => {
    setDialog({
      title: t("general.error_title"),
      message: message,
      buttons: [
        {
          title: t("general.close"),
          onClick: () => {
            hideDialog();
          },
          contained: true,
        },
      ],
      visible: true,
    });
  };

  const showSnackbar = ({ text, color }) => {
    setSnackbar({ open: true, text, color });
  };

  const hideSnackbar = () => {
    setSnackbar(defaultSnackbar);
  };

  const startLoading = (key, message) => {
    let l = loading;
    l[key] = { isLoading: true, message: message };

    setLoading({ ...l });
  };

  const stopLoading = (key) => {
    let l = loading;
    l[key] = { isLoading: false };
    setLoading({ ...l });
  };

  const isLoading = () => {
    for (let key in loading) {
      if (Boolean(loading[key].isLoading)) {
        return key;
      }
    }
    return null;
  };

  const renderLoading = () => {
    return (
      <div className="fullscreen-container">
        <Loading visible={true} message={""} empty />
      </div>
    );
  };

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: 99999,
      color: "#fff",
    },
  }));

  const classes = useStyles();

  const historyPush = (path) => {
    history.push(`/${partner.url}/${selectedLanguage}${path}`);
  };

  const historyReplace = (path) => {
    history.replace(`/${partner.url}/${selectedLanguage}${path}`);
  };

  const onLanguageChange = (l) => {
    setLanguage(l);
    setSelectedLanguage(l);
  };

  const shouldAcceptCookies = (option) => {
    acceptCookies(option)
    setCookiesAcc(true)
  }

  return (
    <div className="App">
      <Helmet>
        {showTrackingCode && <script async src={`https://www.googletagmanager.com/gtag/js?id=G-${GA_ID}`}></script>}
        {showTrackingCode && <script>
          {
          `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-${GA_ID}');`
          }
        </script>}
      </Helmet>
      <ThemeProvider theme={theme}>
        {!!partner && authStatusFound ? (
          <Router history={history}>
            <PageContainer
              history={history}
              onSignOut={signOut}
              email={email}
              partner={partner}
              onLanguageChange={onLanguageChange}
              languages={languages}
              categories={categories}
              documentGroups={documentGroups}
              historyPush={historyPush}
              historyReplace={historyReplace}
              selectedLanguage={selectedLanguage}
            >
              <Routes
                history={history}
                showSnackbar={showSnackbar}
                showError={showError}
                showDialog={showDialog}
                hideDialog={hideDialog}
                startLoading={startLoading}
                stopLoading={stopLoading}
                isLoading={isLoading}
                partner={partner}
                historyPush={historyPush}
                historyReplace={historyReplace}
                selectedLanguage={selectedLanguage}
                categories={categories}
                documentGroups={documentGroups}
                userDocumentGroups={userDocumentGroups}
                userData={userData}
              />
            </PageContainer>
            {!cookiesAcc && <CookiesSection
              shouldAcceptCookies={shouldAcceptCookies}
              partner={partner}
              currentLanguage={selectedLanguage}
            />}
          </Router>
        ) : noPartner ? (
          <div className="no_partner_container">
            <span>{t('general.no_partner')}</span>
          </div>
        ) : (
          renderLoading()
        )}
        {/* <Button onClick={testBCE}>Test BCE</Button> */}
        <Loading
          visible={Boolean(isLoading())}
          message={!!loading[isLoading()] ? loading[isLoading()].message : ""}
        />
        {/* <Backdrop className={classes.backdrop} open={Boolean(isLoading())}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <span style={{ marginBottom: 20 }}>{ !!loading[isLoading()] ? loading[isLoading()].message : '' }</span>
            <CircularProgress color="primary" />
          </div>
        </Backdrop> */}
        <Dialog
          scroll="body"
          title={dialog.title}
          message={dialog.message}
          buttons={dialog.buttons}
          visible={dialog.visible}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={hideSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={hideSnackbar} severity={snackbar.color}>
            {snackbar.text}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import _ from "lodash";
import DocumentBlock from "../components/DocumentBlock";
import ExpirationForm from "../components/ExpirationForm";
import ShareForm from "../components/ShareForm";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import i18n from "../utils/i18n";
import { partnerCopySuffix, sortedArrayFromObject } from "../utils";
import {
  deleteUserDocumentGroup,
  updateUserDocumentGroup,
  getShareLink,
  revokeShareLink,
  createShareLink,
} from "../services/database";
const t = i18n.instance().t;

const Dashboard = (props) => {
  const {
    history,
    partner,
    documentGroups,
    startLoading,
    stopLoading,
    showError,
    showDialog,
    hideDialog,
    showSnackbar,
    historyPush,
  } = props;

  const [userDocumentGroups, setUserDocumentGroups] = useState([]);
  const [expirationForm, setExpirationForm] = useState({
    isOpen: false,
    documentGroup: null,
    index: -1,
  });

  const [shareForm, setShareForm] = useState({
    isOpen: false,
    documentGroup: null,
    document: null,
  });

  useEffect(() => {
    const udg = sortedArrayFromObject(
      props.userDocumentGroups,
      "updated",
      false
    );
    let filteredUdg = [];
    for (let i in udg) {
      if (!!documentGroups[udg[i].group]) {
        filteredUdg.push(udg[i]);
      }
    }
    setUserDocumentGroups([...filteredUdg]);
  }, [props.userDocumentGroups, documentGroups]);

  const createDocument = () => {
    historyPush("/document");
  };

  const editDocument = (documentId) => {
    historyPush(`/mydocument/${documentId}`);
  };

  const promptDeleteGroup = (group, userGroupId) => {
    showDialog(
      t(`document_block.confirm_deletion`, {}, partnerCopySuffix(partner)),
      t("document_block.confirm_deletion_message", { group: group.name }),
      [
        {
          title: t("general.no"),
          type: "default",
          onClick: () => {
            hideDialog();
          },
        },
        {
          title: t("general.yes"),
          type: "danger",
          contained: true,
          onClick: () => {
            deleteGroup(userGroupId);
            hideDialog();
          },
        },
      ]
    );
  };

  const deleteGroup = async (groupId) => {
    startLoading("deleting", t("dashboard.deleting"));
    let result = await deleteUserDocumentGroup(groupId);
    stopLoading("deleting");
    if (result.error) {
      showError(result.error.message);
    }
  };

  const showExpirationForm = (document, index) => {
    setExpirationForm({ isOpen: true, documentGroup: document, index: index });
  };

  const closeExpirationForm = () => {
    setExpirationForm({ isOpen: false, documentGroup: null, index: -1 });
  };

  const saveExpirationDate = (date) => async () => {
    startLoading("expiration", t("dashboard.updating_date"));
    let result = await updateUserDocumentGroup(
      { expires: date },
      expirationForm.documentGroup.id
    );
    stopLoading("expiration");
    closeExpirationForm();
    if (!!result.error) {
      console.log(result.error);
    }
  };

  const openShareForm = (documentGroupId, templateId) => {
    setShareForm({
      isOpen: true,
      document: templateId,
      documentGroup: documentGroupId,
    });
  };

  const closeShareForm = () => {
    setShareForm({ isOpen: false, documentGroup: null, document: null });
  };

  const shareDocument = async (documentGroupId, templateId) => {
    openShareForm(documentGroupId, templateId);
  };

  const renderDocuments = () => {
    return (
      <div className="stretch column mt-2">
        {userDocumentGroups.map((group, i) => {
          return (
            <DocumentBlock
              key={`document_${i}`}
              startLoading={startLoading}
              stopLoading={stopLoading}
              showError={showError}
              showDialog={showDialog}
              hideDialog={hideDialog}
              documentGroup={group}
              group={documentGroups[group.group]}
              editDocument={() => {
                editDocument(group.id);
              }}
              deleteGroup={(documentGroup, userGroupId) => {
                promptDeleteGroup(documentGroup, userGroupId);
              }}
              onExpirationOpen={() => {
                showExpirationForm(group, i);
              }}
              shareDocument={shareDocument}
            />
          );
        })}
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={4}
        className="body-container"
        direction="column"
      >
        <Grid item>
          <img src={"/assets/images/empty.png"} className="empty-img" />
        </Grid>
        <Grid item>
          <h5 className="primary-title centered narrow">
            {t("dashboard.no_docs")}
          </h5>
        </Grid>
        <Grid item>
          <Button
            className="mt-2"
            variant="contained"
            size="large"
            startIcon={<NoteAddIcon />}
            className="custom-button secondary uppercase outlined"
            size="large"
            onClick={createDocument}
          >
            {t("dashboard.create_doc")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderFooter = () => {
    return (
      <Grid
        className="legal-footer"
        container
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <a
            target="blank"
            href={t("legal.security_url")}
            className="legal-link"
          >
            {t("legal.security")}
          </a>
        </Grid>
        <div className="legal-divider" />
        <Grid item>
          <a
            target="blank"
            href={t("legal.privacy_url")}
            className="legal-link"
          >
            {t("legal.privacy")}
          </a>
        </Grid>
        <div className="legal-divider" />
        <Grid item>
          <a target="blank" href={t("legal.legal_url")} className="legal-link">
            {t("legal.legal")}
          </a>
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    return (
      <div className="pt-4">
        <h4 className="primary-title">{t("dashboard.your_docs")}</h4>
        {renderDocuments()}
      </div>
    );
  };

  return (
    <div className="body-container">
      <Container maxWidth="md" className="body-container">
        {!!props.isLoading() && _.isEmpty(userDocumentGroups) ? (
          <div />
        ) : !_.isEmpty(userDocumentGroups) ? (
          renderContent()
        ) : (
          renderEmpty()
        )}
        <ExpirationForm
          open={expirationForm.isOpen}
          date={
            expirationForm.documentGroup
              ? expirationForm.documentGroup.expires
              : null
          }
          onClose={closeExpirationForm}
          onSave={saveExpirationDate}
        />
        <ShareForm
          open={shareForm.isOpen}
          documentGroup={shareForm.documentGroup}
          template={shareForm.document}
          getShareLink={(udg, d) => getShareLink(udg, d)}
          createLink={(udg, d) => createShareLink(udg, d)}
          revokeLink={(udg, d) => revokeShareLink(udg, d)}
          onClose={closeShareForm}
          startLoading={startLoading}
          stopLoading={stopLoading}
          showSnackbar={showSnackbar}
        />
      </Container>
    </div>
  );
};

export default Dashboard;

import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { isUserSignedIn } from '../services/auth'

import { defaultAuthorizedPath, defaultUnauthorizedPath } from './index'

const RouteWrapper = (props) => {

  const { partner, selectedLanguage, isPrivate, isGeneral, component: Component } = props

  const isSignedIn = isUserSignedIn()

  if(!isGeneral && isPrivate && !isSignedIn) {
    return <Redirect to={`/${partner.url}/${selectedLanguage}${defaultUnauthorizedPath}`} />
  }

  if(!isGeneral &&!isPrivate && isSignedIn) {
    return <Redirect to={`/${partner.url}/${selectedLanguage}${defaultAuthorizedPath}`} />
  }

  return <Route render={(p) => <Component {...p} {...props} />} />
}


export default RouteWrapper

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
}

RouteWrapper.defaultProps = {
  isPrivate: false
}
import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import nextIcon from '../assets/next.svg';
import i18n from '../utils/i18n';

const t = i18n.instance().t;

const TermsAndConditions = ({ selectedLanguage }) => {
  const legalWrapper = useRef();
  const history = useHistory();

  useEffect(() => {
    if (legalWrapper) {
      const button = legalWrapper.current.querySelectorAll(".button-arrow");
      const containEl = legalWrapper.current.querySelectorAll(".p-contain");
      const imgArrow = legalWrapper.current.querySelectorAll(".img-arrow");

      for (let i = 0; i <= button.length - 1; i++) {
        button[i].addEventListener("click", (e) => {
          e.preventDefault();
          containEl[i].classList.toggle("p-contain--close");
          imgArrow[i].classList.toggle("img-arrow--rotate");
        });
      }
    }
  }, []);

  return (
    <section className="mg-center legal-page" ref={legalWrapper}>
      <a href="#" className="link-back" onClick={(e) => { e.preventDefault(); history.goBack(); }}>
        <div className="link-content">
          <span>
            <svg className="svg-back">
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
            </svg>
          </span>
          <span> { t('terms_and_conditions.text1')} </span>
        </div>
      </a>
      <div className="legal-container">
        <h2 className=" title title--big">{ t('terms_and_conditions.text2')}</h2>
        <ul className="list">
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('terms_and_conditions.text3')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('terms_and_conditions.text4')}</p>
              <ul>
                <li>
                  <p>{ t('terms_and_conditions.text5')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text6')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text7')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text8')}</p>
                </li>
              </ul>
              <p>{ t('terms_and_conditions.text9')}</p>
              <p>{ t('terms_and_conditions.text10')}</p>
              <p>{ t('terms_and_conditions.text11')}</p>
              <p>{ t('terms_and_conditions.text12')}</p>
              <p>{ t('terms_and_conditions.text13')}</p>
              <p>{ t('terms_and_conditions.text14')}</p>
            </div>
          </li>
          <li className="list__el">
            <button className="button-arrow">
              <div>
                <span className="img-arrow"><img src={nextIcon} alt="arrow" /></span>
                <span>
                  <h3 className="title title--midle">{ t('terms_and_conditions.text15')}</h3>
                </span>
              </div>
            </button>
            <div className="p-contain p-contain--close">
              <p>{ t('terms_and_conditions.text16')}</p>
              <p>{ t('terms_and_conditions.text17')}</p>
              <ul>
                <li>
                  <p>{ t('terms_and_conditions.text18')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text19')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text20')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text21')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text22')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text23')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text24')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text25')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text26')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text27')}</p>
                </li>
              </ul>
              <p>{ t('terms_and_conditions.text28')}</p>
              <p>{ t('terms_and_conditions.text29')}</p>
              <p>{ t('terms_and_conditions.text30')}</p>
              <p>{ t('terms_and_conditions.text31')}</p>
              <p>{ t('terms_and_conditions.text32')}</p>
            </div>
          </li>
          <li className="list__el">
            <button className="button-arrow">
              <div>
                <span className="img-arrow"><img src={nextIcon} alt="arrow" /></span>
                <span>
                  <h3 className="title title--midle">{ t('terms_and_conditions.text33')}</h3>
                </span>
              </div>
            </button>
            <div className="p-contain p-contain--close">
              <p>{ t('terms_and_conditions.text34')}</p>
              <p>{ t('terms_and_conditions.text35')}</p>
              <p>{ t('terms_and_conditions.text36')}</p>
              <ul>
                <li>
                  <p>{ t('terms_and_conditions.text37')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text38')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text39')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text40')}</p>
                </li>
                <li>
                  <p>{ t('terms_and_conditions.text41')}</p>
                </li>
              </ul>
            </div>
          </li>
          <li className="list__el">
            <button className="button-arrow">
              <div>
                <span className="img-arrow"><img src={nextIcon} alt="arrow" /></span>
                <span>
                  <h3 className="title title--midle">{ t('terms_and_conditions.text42')}</h3>
                </span>
              </div>
            </button>
            <div className="p-contain p-contain--close">
              <p>{ t('terms_and_conditions.text43')}</p>
              <p>{ t('terms_and_conditions.text44')}</p>
              <p>{ t('terms_and_conditions.text45')}</p>
              <p>{ t('terms_and_conditions.text46')}</p>
            </div>
          </li>
          <li className="list__el">
            <button className="button-arrow">
              <div>
                <span className="img-arrow"><img src={nextIcon} alt="arrow" /></span>
                <span>
                  <h3 className="title title--midle">{ t('terms_and_conditions.text47')}</h3>
                </span>
              </div>
            </button>
            <div className="p-contain p-contain--close">
              <div>
                <h4 className="title title--small">{ t('terms_and_conditions.text48')}</h4>
                <p>{ t('terms_and_conditions.text49')}</p>
                <p>{ t('terms_and_conditions.text50')}</p>
                <p>{ t('terms_and_conditions.text51')}</p>
              </div>
              <div>
                <h4 className="title title--small">{ t('terms_and_conditions.text52')}</h4>
                <p>{ t('terms_and_conditions.text53')}</p>
                <p>{ t('terms_and_conditions.text54')}</p>
                <p>{ t('terms_and_conditions.text55')}</p>
                <p>{ t('terms_and_conditions.text56')}</p>
                <p>{ t('terms_and_conditions.text57')}</p>
                <ul>
                  <li>
                    <p>{ t('terms_and_conditions.text58')}</p>
                  </li>
                  <li>
                    <p>{ t('terms_and_conditions.text59')}</p>
                  </li>
                  <li>
                    <p>{ t('terms_and_conditions.text60')}</p>
                  </li>
                  <li>
                    <p>{ t('terms_and_conditions.text61')}</p>
                  </li>
                  <li>
                    <p>{ t('terms_and_conditions.text62')}</p>
                  </li>
                </ul>
                <p>{ t('terms_and_conditions.text63')}</p>
                <ul>
                  <li>
                    <p>{ t('terms_and_conditions.text64')}</p>
                  </li>
                  <li>
                    <p>{ t('terms_and_conditions.text65')}</p>
                  </li>
                  <li>
                    <p>{ t('terms_and_conditions.text66')}</p>
                  </li>
                  <li>
                    <p>{ t('terms_and_conditions.text67')}</p>
                  </li>
                </ul>
                <p>{ t('terms_and_conditions.text68')}</p>
              </div>
            </div>
          </li>
          <li className="list__el list__el--mg">
            <button className="button-arrow">
              <div>
                <span className="img-arrow"><img src={nextIcon} alt="arrow" /></span>
                <span>
                  <h3 className="title title--midle">{ t('terms_and_conditions.text69')}</h3>
                </span>
              </div>
            </button>
            <div className="p-contain p-contain--close">
              <div>
                <h4 className="title title--small">{ t('terms_and_conditions.text70')}</h4>
                <p>{ t('terms_and_conditions.text71')}</p>
                <p>{ t('terms_and_conditions.text72')}</p>
                <p>{ t('terms_and_conditions.text73')}</p>
              </div>
              <div>
                <h4 className="title title--small">{ t('terms_and_conditions.text74')}</h4>
                <p>{ t('terms_and_conditions.text75')}</p>
                <p>{ t('terms_and_conditions.text76')}</p>
              </div>
              <div>
                <h4 className="title title--small">{ t('terms_and_conditions.text77')}</h4>
                <p>{ t('terms_and_conditions.text78')}</p>
                <p>{ t('terms_and_conditions.text79')} <a
                    href=" https://www.cfm-fbc.be/fr">https://www.cfm-fbc.be/fr</a> { t('terms_and_conditions.text80')}</p>
                <p>{ t('terms_and_conditions.text81')}</p>
                <p>{ t('terms_and_conditions.text82')}</p>
                <p>{ t('terms_and_conditions.text83')}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default TermsAndConditions;
import React from 'react';
// import { Link } from 'react-router-dom';
// import _ from 'lodash';
// import Button from '@material-ui/core/Button';
// import Container from '@material-ui/core/Container';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import Grid from '@material-ui/core/Grid';
// import IconButton from '@material-ui/core/IconButton';
// import NoteAdd from '@material-ui/icons/NoteAdd'
// import Loading from './Loading'
// import Dialog from '@material-ui/core/Dialog';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import Chip from '@material-ui/core/Chip';
// import DeleteIcon from '@material-ui/icons/Delete';
// import Tooltip from '@material-ui/core/Tooltip';

// import * as auth from '../services/auth'
// const moment = require('moment')

class Landing extends React.Component {

  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     documentGroups: !_.isEmpty(props.documentGroups) ? props.documentGroups : [],
  //     templates: !_.isEmpty(props.templates) ? props.templates : [],
  //     filteredTemplates: !_.isEmpty(props.templates) ? props.templates : [],
  //     loadingTemplates: false,
  //     loadingDocumentGroups: false,
  //     filter: {
  //       language: 'all',
  //       group: 'all',
  //       sortBy: 'updated',
  //       order: 'desc'
  //     }
  //   }
  // }

  render() {
    return (
      <div>
        <span>Client app landing page</span>
      </div>
    )
  }

}

export default Landing

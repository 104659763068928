import React, { useRef, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import nextIcon from '../assets/next.svg';
import i18n from '../utils/i18n';

const t = i18n.instance().t;

const PrivacyPolicy = ({ selectedLanguage }) => {
  const legalWrapper = useRef();
  const history = useHistory();

  useEffect(() => {
    if (legalWrapper) {
      const button = legalWrapper.current.querySelectorAll(".button-arrow");
      const containEl = legalWrapper.current.querySelectorAll(".p-contain");
      const imgArrow = legalWrapper.current.querySelectorAll(".img-arrow");

      for (let i = 0; i <= button.length - 1; i++) {
        button[i].addEventListener("click", (e) => {
          e.preventDefault();
          containEl[i].classList.toggle("p-contain--close");
          imgArrow[i].classList.toggle("img-arrow--rotate");
        });
      }
    }
  }, []);

  return (
    <section className="mg-center legal-page" ref={legalWrapper}>
      <a href="#" className="link-back" onClick={(e) => { e.preventDefault(); history.goBack(); }}>
        <div className="link-content">
          <span>
            <svg className="svg-back">
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
            </svg>
          </span>
          <span> { t('privacy.text1')} </span>
        </div>
      </a>
      <div className="legal-container">
        <h2 className=" title title--big">{ t('privacy.text2')}</h2>
        <ul className="list">
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text3')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text4')}</p>
              <p>{ t('privacy.text5')}</p>
              <p>{ t('privacy.text6')}</p>
              <p>{ t('privacy.text7')}</p>
              <p>{ t('privacy.text8')}</p>
              <p>{ t('privacy.text9')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text10')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text11')}</p>
              <ul>
                <li>
                  <p>{ t('privacy.text12')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text13')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text14')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text15')}</p>
                </li>
              </ul>
              <p>{ t('privacy.text16')}</p>
              <p>{ t('privacy.text17')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text18')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text19')}</p>
              <ul>
                <li>
                  <p>{ t('privacy.text20')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text21')}</p>
                </li>
              </ul>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text22')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text23')}</p>
              <ul>
                <li>
                  <p>{ t('privacy.text24')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text25')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text26')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text27')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text28')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text29')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text30')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text31')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text32')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text33')}</p>
                </li>
              </ul>
              <p>{ t('privacy.text34')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text35')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text36')}</p>
            </div>
          </li>
          <li className="list__el list__el--mg">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text37')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text38')}</p>
              <p>{ t('privacy.text39')}</p>
              <p>{ t('privacy.text40')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text41')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text42')}</p>
              <p>{ t('privacy.text43')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text44')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text45')}</p>
              <p>{ t('privacy.text46')}</p>
              <p>{ t('privacy.text47')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text48')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text49')}</p>
              <p>{ t('privacy.text50')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text51')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text52')}</p>
              <ul>
                <li>
                  <p>{ t('privacy.text53')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text54')}</p>
                </li>
              </ul>
              <p>{ t('privacy.text55')}</p>
              <p>{ t('privacy.text56')}</p>
              <p>{ t('privacy.text57')}</p>
              <p>{ t('privacy.text58')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text59')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text60')}</p>
              <ul>
                <li>
                  <p>{ t('privacy.text61')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text62')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text63')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text64')}</p>
                </li>
              </ul>
              <p>{ t('privacy.text65')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text66')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text67')}</p>
              <ul>
                <li>
                  <p>{ t('privacy.text68')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text69')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text70')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text71')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text72')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text73')}</p>
                </li>
              </ul>
              <p>{ t('privacy.text74')}</p>
              <ul>
                <li>
                  <p>{ t('privacy.text75')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text76')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text77')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text78')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text79')}</p>
                </li>
              </ul>
              <p>{ t('privacy.text80')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text81')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text82')}</p>
              <ul>
                <li>
                  <p>{ t('privacy.text83')}</p>
                </li>
                <li>
                  <p>{ t('privacy.text84')}</p>
                </li>
              </ul>
              <p>{ t('privacy.text85')}</p>
              <p>{ t('privacy.text86')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text87')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text88')}</p>
              <p>{ t('privacy.text89')}</p>
              <p>{ t('privacy.text90')}</p>
              <p>{ t('privacy.text91')}</p>
              <p>{ t('privacy.text92')}</p>
              <p>{ t('privacy.text93')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text94')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text95')}</p>
              <p>{ t('privacy.text96')}</p>
              <p>{ t('privacy.text97')}</p>
              <p>{ t('privacy.text98')}</p>
              <p>{ t('privacy.text99')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text100')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text101')}</p>
              {selectedLanguage === 'fr' && <Fragment>
                <p>{ t('privacy.text102')} <a
                  href="http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080">
                  http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080</a>.</p>
                <p>{ t('privacy.text103')}</p>
              </Fragment>}
              <h4 className="title title--small">{ t('privacy.text104')}</h4>
              <ul>
                <li>
                  <p>{ t('privacy.text105')}</p>
                </li>
                <li>
                  <p>+32 (0)2 274 48 00</p>
                </li>
                <li>
                  <p>+32 (0)2 274 48 35</p>
                </li>
                <li>
                  <p>contact(at)apd-gba.be</p>
                </li>
              </ul>
              {selectedLanguage === 'nl' && <p>{ t('privacy.text105a')}</p>}
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text106')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text107')}</p>
              <p>{ t('privacy.text108')}</p>
              <p>{ t('privacy.text109')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text110')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text111')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('privacy.text112')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('privacy.text113')}</p>
              <p>{ t('privacy.text114')}</p>
            </div>
          </li>
        </ul>
      </div>

      <footer>
        <p>{ t('privacy.text115')}</p>
      </footer>
    </section>
  );
}

export default PrivacyPolicy;
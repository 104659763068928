import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'

class Alert extends Component {

  render() {
    const { title, message, buttons, visible } = this.props
    return (
      <Dialog aria-labelledby="message-dialog" open={visible} disableBackdropClick={true} disableEscapeKeyDown={true}>
        <div className="popup centered">
          <h3 id="message-dialog">{ title }</h3>
          <p id="simple-modal-description" className="mt-2">
            { message }
          </p>
          <Grid container spacing={2} justify="center" alignItems="center">
            { buttons.map((button, i) => {
              return (
                <Grid item key={`alert_button_${i}`}>
                  <Button
                    variant={button.contained ? 'contained' : 'text'}
                    color={buttonColorForType(button.type)}
                    className="flexible-button"
                    onClick={button.onClick}>
                    { button.title }
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        </div>
      </Dialog>
    )
  }
}

export default Alert

const buttonColorForType = type => {
  switch(type) {
    case 'important':
      return 'primary'
    case 'danger':
      return 'secondary'
    default:
      return 'primary'
  }
}
import React from 'react';
import Grid from '@material-ui/core/Grid';
import i18n from '../utils/i18n';
import { Link } from 'react-router-dom';

const t = i18n.instance().t;

const Footer = (props) => {
  const { partner, selectedLanguage } = props
  return(
    <Grid className="legal-footer" container justify="center" alignItems="center">
      <Grid item>
        <Link to={`/${partner.url}/${selectedLanguage}/terms-and-conditions`} className="legal-link">{ t('footer.disclaimer')}</Link>
      </Grid>
      <div className="legal-divider" />
      <Grid item>
        <Link to={`/${partner.url}/${selectedLanguage}/privacy-policy`} className="legal-link">{ t('footer.privacy_policy')}</Link>
      </Grid>
      <div className="legal-divider" />
      <Grid item>
        <Link to={`/${partner.url}/${selectedLanguage}/cookie-policy`} className="legal-link">{ t('footer.cookie_policy')}</Link>
      </Grid>
      <div className="legal-divider" />
      <Grid item>
        <a target="blank" href="https://www.dataprivacybox.com/" className="legal-link">{ t('footer.powered_by')}</a>
      </Grid>
    </Grid>
  );
}

export default Footer;
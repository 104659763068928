import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Footer, Navbar } from '../components'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '80px',
    ...theme.mixins.toolbar,
  },
  content: {
    // height: '100vh'
    // padding: theme.spacing(3),
    paddingBottom: 40,
    flexGrow: 1
  },
  drawerTitle: {
    paddingLeft: theme.spacing(1)
  }
}));

const PageContainer = (props) => {
  const classes = useStyles()

  const { email, onSignOut, history, onLanguageChange, languages, categories, documentGroups, historyPush, historyReplace, selectedLanguage, partner } = props

  return (
    <div className={`${classes.root} full-height-container`}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar)}
      >
      <Toolbar>
          <Navbar
            historyPush={historyPush}
            historyReplace={historyReplace}
            onLanguageChange={onLanguageChange}
            languages={languages}
            email={email}
            onSignOut={onSignOut}
            categories={categories}
            documentGroups={documentGroups}
            history={history}
            partner={props.partner}
            selectedLanguage={selectedLanguage} />
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        { props.children }
      </main>
      <Footer selectedLanguage={selectedLanguage} partner={partner} />
    </div>
  );
}

export default PageContainer
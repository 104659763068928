import cookie from 'react-cookies';
import i18n from "./i18n";
import { env } from "../config.json"

const t = i18n.instance().t;
const COOKIES_ACCEPTED_KEY = 'dpbox_cookie';
const GA_ID = 'ZM38W4LJYC';

if(cookie.load(`_ga_7TVL1J2BPP`)) { // remove cookie with previous ga id
  cookie.remove(`_ga_7TVL1J2BPP`, { path: '/', domain: window.location.hostname })
}

const languageFromCode = (code) => {
  switch (code) {
    case "fr":
      return "Français";
    case "en":
      return "English";
    case "nl":
      return "Nederlands";
    default:
      return "undefined";
  }
};

const sortedArrayFromObject = (object, sortKey, asc) => {
  let array = [];
  for (let key in object) {
    let item = object[key];
    item.id = key;
    array.push(item);
  }
  array.sort((a, b) => {
    if (a[sortKey] > b[sortKey]) return asc ? 1 : -1;
    if (a[sortKey] < b[sortKey]) return asc ? -1 : 1;
    return 0;
  });
  return array;
};

const sortedArray = (array, extract, asc) => {
  array.sort((a, b) => {
    if (extract(a) > extract(b)) return asc ? 1 : -1;
    if (extract(a) < extract(b)) return asc ? -1 : 1;
    return 0;
  });
  return array;
};

const updateUrlParams = (history, newParams) => {
  let params = paramsFromSearch(history.location.search);
  for (let key in newParams) {
    params[key] = newParams[key];
  }

  let newSearch = `?`;
  for (let key in params) {
    if (newSearch !== "?") {
      newSearch += "&";
    }
    newSearch += `${key}=${params[key]}`;
  }

  history.push({
    pathname: history.location.pathname,
    search: newSearch,
  });
};

const paramsFromSearch = (search) => {
  if (search.length <= 2) {
    return {};
  }
  search = search.substring(1, search.length);
  let components = search.split("&");
  let params = {};
  for (let i in components) {
    let keyValue = components[i].split("=");
    params[keyValue[0]] = keyValue[1];
  }
  return params;
};

const dataTypes = {
  string: {
    label: "Text",
  },
  number: {
    label: "Number",
  },
  multiselect: {
    label: "Multi select",
  },
  select: {
    label: "Opt-in",
  },
};

const dataTypesArray = ["string", "number", "multiselect", "select"];

const languageFromLocale = (locale, includeCountry = true) => {
  const components = locale.split("_");
  const language = t(`language_long.${components[0].toLowerCase()}`);
  const country = t(`country_long.${components[1].toLowerCase()}`);
  // todo use localized values
  if (!includeCountry) {
    return `${language}`;
  } else {
    return `${language} (${country})`;
  }
};

const languageCodeFromLocale = (locale) => {
  return locale.split("_")[0].toLowerCase();
};

const isPartnerSNI = (partner) => {
  return partner.id === 'dEhCXaqkH72jICSUNaQJ'
}

const partnerCopySuffix = (partner) => {
  switch(partner.id) {
    case 'dEhCXaqkH72jICSUNaQJ': // sni-nsz
      return '__sni'
    case 'qg9A9UiThNAg2T7vhqyt': // assurdeal
      return '__assurdeal'
    default:
      return ''
  }
}

const isPartnerFROnly = (partner) => {
  if(!partner) {
    return false
  }
  return (partner.locales.length === 1 && partner.locales[0] === 'fr_FR')
}

const enterpriseTypeOptionsForPartner = (partner) => {
  if(isPartnerFROnly(partner)) { // originally only for Assurdeal
    return [
      "sarl",
      "sas",
      "sa",
      "eurl",
      "snc",
      "ei"
    ]
  } else {
    return [
      "personal",
      "srl",
      "sprl",
      "sa",
      "sc",
      "asbl",
      "snc",
      "scs",
      "sca",
      "ei"
    ]
  }
}

const cookiesAccepted = () => {
  let accepted = cookie.load(COOKIES_ACCEPTED_KEY)
  return accepted
}

const acceptCookies = (type) => {
  const obj = { ...type }
  cookie.save(COOKIES_ACCEPTED_KEY, JSON.stringify(obj), { path: '/' })
}

export {
  languageFromCode,
  sortedArrayFromObject,
  dataTypes,
  dataTypesArray,
  sortedArray,
  updateUrlParams,
  paramsFromSearch,
  t,
  languageFromLocale,
  isPartnerSNI,
  languageCodeFromLocale,
  partnerCopySuffix,
  acceptCookies,
  cookiesAccepted,
  GA_ID,
  enterpriseTypeOptionsForPartner,
  isPartnerFROnly
};

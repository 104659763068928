import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import Route from './Route'
import {
  Dashboard,
  DocumentForm,
  Landing,
  Login,
  Profile,
  AuthAction,
  Onboarding,
  GetStarted,
  PrivacyPolicy,
  CookiePolicy,
  TermsAndConditions
} from '../pages'

const defaultUnauthorizedPath = '/login'
const defaultAuthorizedPath = '/dashboard'

const publicPaths = ['/login', '/register', '/reset-password', '/__/authentication/action', '/privacy-policy', '/cookie-policy', '/terms-and-conditions' ]

const isPathPrivate = (path) => {
  return publicPaths.indexOf(path) === -1
}

const Routes = (props) => {
  const { partner, selectedLanguage } = props
  return (
    <Switch>
      <Route {...props} path="/__/authentication/action" component={AuthAction} isPrivate={false} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/login`} component={Login} isPrivate={isPathPrivate('/login')} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/register`} component={Login} isPrivate={isPathPrivate('/register')} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/reset-password`} component={Login} isPrivate={isPathPrivate('/reset-password')} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/dashboard`} component={Dashboard} isPrivate={isPathPrivate('/dashboard')} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/document/:id`} component={DocumentForm} isPrivate={isPathPrivate('/document')} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/document`} component={DocumentForm} isPrivate={isPathPrivate('/document')} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/mydocument/:id`} component={DocumentForm} isPrivate={isPathPrivate('/mydocument')} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/profile`} component={Profile} isPrivate={isPathPrivate('/profile')} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/onboarding`} component={Onboarding} isPrivate={isPathPrivate('/onboarding')} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/get-started`} component={GetStarted} isPrivate={isPathPrivate('/get-started')} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/privacy-policy`} component={PrivacyPolicy} isGeneral={true} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/cookie-policy`} component={CookiePolicy} isGeneral={true} />
      <Route {...props} path={`/${partner.url}/${selectedLanguage}/terms-and-conditions`} component={TermsAndConditions} isGeneral={true} />
      <Route {...props} component={() => <Redirect to={`/${partner.url}/${selectedLanguage}/dashboard`} /> } />
    </Switch>
  )
}

export default Routes

export {
  defaultAuthorizedPath,
  defaultUnauthorizedPath,
  isPathPrivate
}

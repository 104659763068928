import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import nextIcon from '../assets/next.svg';
import i18n from '../utils/i18n';

const t = i18n.instance().t;

const CookiePolicy = ({ selectedLanguage }) => {
  const legalWrapper = useRef();
  const history = useHistory();

  useEffect(() => {
    if (legalWrapper) {
      const button = legalWrapper.current.querySelectorAll(".button-arrow");
      const containEl = legalWrapper.current.querySelectorAll(".p-contain");
      const imgArrow = legalWrapper.current.querySelectorAll(".img-arrow");

      for (let i = 0; i <= button.length - 1; i++) {
        button[i].addEventListener("click", (e) => {
          e.preventDefault();
          containEl[i].classList.toggle("p-contain--close");
          imgArrow[i].classList.toggle("img-arrow--rotate");
        });
      }
    }
  }, []);

  return (
    <section className="mg-center legal-page" ref={legalWrapper}>
      <a href="#" className="link-back" onClick={(e) => { e.preventDefault(); history.goBack(); }}>
        <div className="link-content">
          <span>
            <svg className="svg-back">
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
            </svg>
          </span>
          <span> { t('cookie_policy.text1')} </span>
        </div>
      </a>
      <div className="legal-container">
        <h2 className=" title title--big">{ t('cookie_policy.text2')} </h2>
        <ul className="list">
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('cookie_policy.text3')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('cookie_policy.text4')}</p>
              <p>{ t('cookie_policy.text5')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('cookie_policy.text6')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('cookie_policy.text7')}</p>
              <p>{ t('cookie_policy.text8')}</p>
              <p>{ t('cookie_policy.text9')} </p>
              <p>{ t('cookie_policy.text10')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('cookie_policy.text11')} </h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('cookie_policy.text12')}</p>
              <p>{ t('cookie_policy.text13')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('cookie_policy.text14')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('cookie_policy.text15')}</p>
              <p>{ t('cookie_policy.text16')}</p>
              <p>{ t('cookie_policy.text17')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('cookie_policy.text18')} </h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('cookie_policy.text19')}</p>
              <p>{ t('cookie_policy.text20')}</p>
              <ul>
                <li>
                  <p>Firefox : <a
                    href="https://support.mozilla.org/fr/kb/effacer-les-cookies-pour-supprimer-les-information ">https://support.mozilla.org/fr/kb/effacer-les-cookies-pour-supprimer-les-information
                                            </a></p>
                </li>
                <li>
                  <p>Chrome : <a
                    href="https://support.google.com/chrome/answer/95647?hl=fr  ">https://support.google.com/chrome/answer/95647?hl=fr
                                            </a></p>
                </li>
                <li>
                  <p>Safari : <a
                    href="https://support.apple.com/fr-be/guide/safari/sfri11471/mac">https://support.apple.com/fr-be/guide/safari/sfri11471/mac</a>
                  </p>
                </li>
                <li>
                  <p>Explorer : <a
                    href="https://support.microsoft.com/fr-be/help/17442/windows-internet-explorer-delete-manage-cookies ">https://support.microsoft.com/fr-be/help/17442/windows-internet-explorer-delete-manage-cookies
                                            </a></p>
                </li>
              </ul>
              <p>{ t('cookie_policy.text21')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('cookie_policy.text22')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <div>
                <h4 className="title title--small">{ t('cookie_policy.text23')}</h4>
                <p>{ t('cookie_policy.text24')}</p>
                <ul>
                  <li>
                    <p>{ t('cookie_policy.text25')}</p>
                  </li>
                  <li>
                    <p>{ t('cookie_policy.text26')}</p>
                  </li>
                  <li>
                    <p>{ t('cookie_policy.text27')}</p>
                  </li>
                  <li>
                    <p>{ t('cookie_policy.text28')}</p>
                  </li>
                </ul>
                <p>{ t('cookie_policy.text29')}</p>
              </div>
              <div>
                <h4 className="title title--small">{ t('cookie_policy.text30')}</h4>
                <p>{ t('cookie_policy.text31')}</p>
                <p>{ t('cookie_policy.text32')}</p>
                <ul>
                  <li>
                    <p>{ t('cookie_policy.text33')}</p>
                  </li>
                  <li>
                    <p>{ t('cookie_policy.text34')}</p>
                  </li>
                  <li>
                    <p>{ t('cookie_policy.text35')}</p>
                  </li>
                  <li>
                    <p>{ t('cookie_policy.text36')}</p>
                  </li>
                  <li>
                    <p>{ t('cookie_policy.text37')}</p>
                  </li>
                </ul>
                <p>{ t('cookie_policy.text38')} </p>
              </div>
              <div>
                <h4 className="title title--small">{ t('cookie_policy.text39')}</h4>
                <p>{ t('cookie_policy.text40')}</p>
                <p>{ t('cookie_policy.text41')}</p>
                <p>{ t('cookie_policy.text42')}</p>
                <p>{ t('cookie_policy.text43')}</p>
                <p>{ t('cookie_policy.text44')}</p>
              </div>
              <div>
                <h4 className="title title--small">{ t('cookie_policy.text45')}</h4>
                <p>{ t('cookie_policy.text46')}</p>
                <p>{ t('cookie_policy.text47')}</p>
                <p>{ t('cookie_policy.text48')}</p>
                <p>{ t('cookie_policy.text49')}</p>
              </div>
              <div>
                <h4 className="title title--small">{ t('cookie_policy.text50')}</h4>
                <p>{ t('cookie_policy.text51')}</p>
                <p>{ t('cookie_policy.text52')}</p>
                <p>{ t('cookie_policy.text53')}</p>
                <table>
                  <tbody>
                    <tr>
                      <td>Facebook</td>
                      <td><a
                        href="https://fr-fr.facebook.com/policies/cookies/">https://fr-fr.facebook.com/policies/cookies/</a>
                      </td>
                    </tr>
                    <tr>
                      <td>Twitter</td>
                      <td><a
                        href="https://help.twitter.com/fr/safety-and-security/twitter-do-not-track">https://help.twitter.com/fr/safety-and-security/twitter-do-not-track</a>
                      </td>
                    </tr>
                    <tr>
                      <td>Google</td>
                      <td><a
                        href="https://support.google.com/accounts/answer/61416?hl=fr">https://support.google.com/accounts/answer/61416?hl=fr</a>
                      </td>
                    </tr>
                    <tr>
                      <td>Instagram</td>
                      <td><a
                        href="https://help.instagram.com/1896641480634370">https://help.instagram.com/1896641480634370</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <h4 className="title title--small">{ t('cookie_policy.text54')}</h4>
                <p>{ t('cookie_policy.text55')}</p>
              </div>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('cookie_policy.text56')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('cookie_policy.text57')} </p>
              <p>
                { t('cookie_policy.text58')}
                <a href={ selectedLanguage === 'fr' ? 'https://www.sninet.be/fr/disclaimer' : 'https://www.nsz.be/nl/disclaimer'}>{ selectedLanguage === 'fr' ? 'https://www.sninet.be/fr/disclaimer' : 'https://www.nsz.be/nl/disclaimer'}</a>
                { t('cookie_policy.text59')}
              </p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('cookie_policy.text60')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <ul>
                <li>
                  <p>{ t('cookie_policy.text61')}</p>
                </li>
                <li>
                  <p>{ t('cookie_policy.text62')}</p>
                </li>
              </ul>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('cookie_policy.text63')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('cookie_policy.text64')}</p>
            </div>
          </li>
          <li className="list__el">
            <div className="button-arrow">
              <img className="img-arrow" src={nextIcon} alt="arrow" />
              <h3 className="title title--midle">{ t('cookie_policy.text65')}</h3>
            </div>
            <div className="p-contain p-contain--close">
              <p>{ t('cookie_policy.text66')}</p>
              <p>{ t('cookie_policy.text67')}</p>
            </div>
          </li>
        </ul>
      </div>

      <footer>
        <p>{ t('cookie_policy.text68')}</p>
      </footer>
    </section>
  );
}

export default CookiePolicy;
import React, { useState, useEffect, Fragment } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import AddIcon from "@material-ui/icons/Add";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';

import i18n from "../utils/i18n";
import { isPartnerSNI, languageFromLocale } from "../utils";
import { Dialog } from "@material-ui/core";
import topicIcon from '../assets/topic.svg';
import hamburgerMenuIcon from '../assets/hamburger-menu.svg';
import glossaryFR from '../assets/glossary_GDPR_fr.pdf'
import glossaryNL from '../assets/Verklarende_woordenlijst_en_verdere_informatie.pdf'
import assistantFR from '../assets/assistant_GDPR_fr.pdf'
import assistantNL from '../assets/assistant_GDPR_nl.pdf'

const glossaryFile = {
  fr: glossaryFR,
  nl: glossaryNL
}

const assistantFile = {
  fr: assistantFR,
  nl: assistantNL
}

const t = i18n.instance().t;
const getLanguage = i18n.instance().getLanguage;

const Navbar = (props) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [creationMenuAnchor, setCreationMenuAnchor] = useState(null);
  // const [documentGroups, setDocumentGroups] = useState([])
  const [locales, setLocales] = useState([]);
  const [groupsPerCategory, setGroupsPerCategory] = useState({});
  const [hideTimeout, setHideTimeout] = useState(null);
  const [presentationModal, setPresentationModal] = useState({
    isOpen: false
  });
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const {
    partner,
    email,
    onSignOut,
    languages,
    historyPush,
    selectedLanguage,
  } = props;

  useEffect(() => {
    let locs = {};
    for (let key in props.documentGroups) {
      let group = props.documentGroups[key];
      group.id = key;
      if (!locs[group.locale]) {
        locs[group.locale] = {
          code: group.locale,
          groups: [],
          categories: []
        };
      }
      locs[group.locale].groups.push(group);
    }
    for(let key in props.categories) {
      let category = props.categories[key];
      category.id = key;
      const categoryGroups = category.groups || []
      groupsPerCategory[key] = categoryGroups.map(id => props.documentGroups[id]).filter(g => !!g)

      const groupIds = Object.keys(props.documentGroups)
      let shouldShow = false
      for(let id of categoryGroups) {
        if(groupIds.includes(id)) {
          shouldShow = true
          break
        }
      }
      if(!shouldShow) {
        continue
      }
      if (!locs[category.locale]) {
        locs[category.locale] = {
          code: category.locale,
          groups: [],
          categories: [],
        };
      }
      locs[category.locale].categories.push(category);
    }
    let ls = [];
    for (let key in locs) {
      ls.push(locs[key]);
    }
    if(selectedLanguage === 'nl') {
      ls.sort((a, b) => {
        if(a.code > b.code) return -1
        if(b.code > a.code) return 1
        return 0
      })
    }else {
      ls.sort((a, b) => {
        if(a.code < b.code) return -1
        if(b.code < a.code) return 1
        return 0
      })
    }
    setLocales([...ls]);
  }, [props.categories, props.documentGroups, selectedLanguage]);

  const openMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const signOut = async () => {
    closeMenu();
    onSignOut();
  };

  const switchLanguage = (language) => {
    // setLanguage(language)
    props.onLanguageChange(language);
  };

  const openProfile = () => {
    closeMenu();
    historyPush("/profile");
  };

  const openDocuments = () => {
    historyPush("/dashboard");
  };

  const openDocumentCreation = () => {
    closeCreationMenu();
    historyPush("/document");
  };

  const openCreationMenu = (event) => {
    setCreationMenuAnchor(event.currentTarget);
  };

  const showPresentationModal = () => {
    setPresentationModal({
      isOpen: true
    });
  };
  const hidePresentationModal = () => {
    setPresentationModal({
      isOpen: false
    })
  };

  const closeCreationMenu = () => {
    setCreationMenuAnchor(null);
  };

  const openCreation = (groupId) => {
    closeCreationMenu();
    historyPush(`/document/${groupId}`);
  };

  const selectCategory = (category) => {
    closeCreationMenu()
    historyPush(`/document?locale=${category.locale}&category=${category.id}`)
  }

  const startHideTimeout = () => {
    let t = setTimeout(() => {
      closeCreationMenu();
    }, 1000);
    setHideTimeout(t);
  };

  const stopHideTimeout = () => {
    if (!!hideTimeout) {
      clearTimeout(hideTimeout);
      setHideTimeout(null);
    }
  };

  const getLogoUrl = () => {
    if (!!partner) {
      if (!!partner.logos && !!partner.logos[getLanguage()]) {
        return partner.logos[getLanguage()];
      } else if (!!partner.logo) {
        return partner.logo;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const switchLanguageHandler = () => {
    switchLanguage(getLanguage() === 'fr' ? 'nl' : 'fr');
  }

  return (
    <nav id="mainNav" className="navbar navbar-custom">
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button onClick={openDocuments} className="clean-button">
              <img className="navbar-logo" src={getLogoUrl()} alt="partner logo" />
            </Button>
          </Grid>
          {!!email ? (
            <Grid item className="navbar-menu-center">
              <Button
                variant="contained"
                color="primary"
                className="custom-button primary"
                onClick={openDocuments}
              >
                {t("dashboard.my_documents")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="custom-button secondary ml-2"
                startIcon={<AddIcon />}
                onClick={openDocumentCreation}
                onMouseEnter={openCreationMenu}
              >
                {t("dashboard.create_doc")}
              </Button>
            </Grid>
          ) : null}
          <Grid item className="navbar-menu-right">
            <div className="navbar-end-row">
              {/* {languages.length > 1
                ? languages.map((lang, li) => {
                    return (
                      <div key={`nav_lang_${li}`} className="row">
                        <Button
                          disabled={getLanguage() === lang}
                          className={`navbar-item language-button${
                            getLanguage() === lang ? " selected" : ""
                          }`}
                          onClick={() => {
                            switchLanguage(lang);
                          }}
                        >
                          {lang.toUpperCase()}
                        </Button>
                        {li < languages.length - 1 ? (
                          <div className="language-divider" />
                        ) : null}
                      </div>
                    );
                  })
                : null} */}

              {languages.length > 1 ? (
              <IconButton className="lang" onClick={switchLanguageHandler}>
                {getLanguage() === 'fr' ? 'NL' : 'FR'}
              </IconButton>
              ) : null }
              {!!email && partner.button &&
                <Fragment>
                  <IconButton
                    target="_blank"
                    href={getLanguage() === 'fr' ? 'https://www.loom.com/share/ed47ec583bd34ddc80fc5a740ec6f1a8?sharedAppSource=personal_library' : 'https://www.loom.com/share/35b2502c1b604a5f87c4b1eb9efc02a2?sharedAppSource=personal_library'}
                  >
                    <PlayCircleOutlineIcon />
                  </IconButton>
                  <IconButton onClick={showPresentationModal}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Fragment>
              }

              {!!email ? (
                <IconButton
                  className="user"
                  onClick={openMenu}
                  // endIcon={email ? <ArrowDropDownIcon /> : null}
                >
                  <AccountCircleIcon />
                </IconButton>
              ) : null}
            </div>
          </Grid>
          <div className="mobile-menu-trigger" onClick={() => setShowMobileMenu(true)}>
            <img src={hamburgerMenuIcon} alt="" />
          </div>
        </Grid>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={closeMenu}
        >
          <MenuItem dense onClick={openProfile}>
            {t("profile.my_profile")}
          </MenuItem>
          <MenuItem dense className="menu-item-destructive" onClick={signOut}>
            {t("auth.signout")}
          </MenuItem>
        </Menu>

        <Popper
          onMouseLeave={startHideTimeout}
          onMouseEnter={stopHideTimeout}
          open={Boolean(creationMenuAnchor)}
          anchorEl={creationMenuAnchor}
          role={undefined}
          transition
          disablePortal
        >
          <Paper className="mt-2 documents-menu-paper">
            <div className="arrow-top"></div>
            <div className="arrow-top-overlay"></div>
            <ClickAwayListener onClickAway={closeCreationMenu}>
              <MenuList
                autoFocusItem={Boolean(creationMenuAnchor)}
                id="menu-list-grow"
                className="documents-menu"
              >
                <div className="documents-menu__header">
                  <img src={topicIcon} alt=""/>
                  <p>{ t('general.category') }</p>
                </div>
                {locales.map((locale, li) => {
                  return (
                    <div key={`language_${li}`} className="documents-menu__group">
                      <div className="language-header">
                        <span className="language-header-label">
                          {languageFromLocale(locale.code, !isPartnerSNI(partner))}
                        </span>
                      </div>
                      { locale.categories.map((category, ci) => {
                          return (<>
                            <MenuItem
                              className="group-option"
                              key={`category_${ci}`}
                              onClick={() => {
                                selectCategory(category);
                              }}
                            >
                              {category.name}
                            </MenuItem>
                            {groupsPerCategory[category.id].map((group, gi) => {
                              return (
                                <MenuItem
                                  className="group-option secondary"
                                  key={`group_${gi}`}
                                  onClick={() => {
                                    openCreation(group.id);
                                  }}
                                >
                                  {group.name}
                                </MenuItem>
                              );
                            })}
                            </>
                          )
                        })
                      }
                    </div>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Container>

      <Dialog open={presentationModal.isOpen} onClose={hidePresentationModal} className="custom-popup">
        <div className="custom-popup__inner">
          <div className="custom-popup__close" onClick={hidePresentationModal}><CloseIcon /></div>
          {getLanguage() === 'fr' && <h4 className="custom-popup__title">{t("dashboard.document__sni")}</h4>}
          <div className={getLanguage() === 'fr' ? "custom-popup__body" : "custom-popup__body--2"}>
            <div className="custom-popup__text">{t("dashboard.popup_p__sni")}</div>
            <div className="mt-2">
              <a href={assistantFile[getLanguage()]} rel="noopener noreferrer" target="_blank" className="custom-popup__link">{t("dashboard.popup_link_assistant__sni")}</a>
            </div>
            <div className="mt-2">
              <a href={glossaryFile[getLanguage()]} rel="noopener noreferrer" target="_blank" className="custom-popup__link">{t("dashboard.popup_link_glossary__sni")}</a>
            </div>
          </div>
        </div>
      </Dialog>

      <Drawer anchor="right" open={showMobileMenu} onClose={() => setShowMobileMenu(false)}>
        <div className="mobile-menu-wrapper">
          <Button
            variant="contained"
            color="primary"
            className="custom-button primary"
            onClick={openDocuments}
          >
            {t("dashboard.my_documents")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="custom-button secondary mt-2 mb-2"
            startIcon={<AddIcon />}
            onClick={openDocumentCreation}
          >
            {t("dashboard.create_doc")}
          </Button>
          <div className="navbar-end-row">
            <IconButton className="lang" onClick={switchLanguageHandler}>
              {getLanguage() === 'fr' ? 'NL' : 'FR'}
            </IconButton>
            {!!email && partner.button &&
              <Fragment>
                <IconButton
                  target="_blank"
                  href={getLanguage() === 'fr' ? 'https://www.loom.com/share/ed47ec583bd34ddc80fc5a740ec6f1a8?sharedAppSource=personal_library' : 'https://www.loom.com/share/35b2502c1b604a5f87c4b1eb9efc02a2?sharedAppSource=personal_library'}
                >
                  <PlayCircleOutlineIcon />
                </IconButton>
                <IconButton onClick={showPresentationModal}>
                  <InfoOutlinedIcon />
                </IconButton>
              </Fragment>
            }

            {!!email ? (
              <IconButton
                className="user"
                onClick={openMenu}
                // endIcon={email ? <ArrowDropDownIcon /> : null}
              >
                <AccountCircleIcon />
              </IconButton>
            ) : null}
          </div>
        </div>
      </Drawer>
    </nav>
  );
};

export default Navbar;

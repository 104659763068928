
import firebase from './firebase'
import config from '../config.json'
import { currentUserUid } from './auth'

// firebase.functions('europe-west1').useFunctionsEmulator('http://localhost:5001')

function generateDocument(userDocumentGroup, group, documentId) {
  let generateFunction = firebase.functions('europe-west1').httpsCallable('generateDocument')
  generateFunction({ userDocumentGroup, documentId, group, author: currentUserUid(), environment: config.env })
}

async function createUserForPartner(email, password, partnerId, sniNumber, language, enterpriseNumber, postcode) {
  let validateFunction = firebase.functions('europe-west1').httpsCallable('createUserForPartner')
  let result = await validateFunction({ email, password, partnerId, environment: config.env, sniNumber, language, enterpriseNumber, postcode })
  return result.data
}

export {
  generateDocument,
  createUserForPartner
}
import React, { Component } from "react";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { TextField, RadioGroup, FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import i18n from "../utils/i18n";
import * as auth from "../services/auth";

const t = i18n.instance().t;
const setLanguage = i18n.instance().setLanguage;

class AuthAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      verificationPassword: "",
      showPassword: false,
      loading: true,
      complete: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    if (!!this.props.language) {
      setLanguage(this.props.language);
    }
    if (this.props.mode == "resetPassword") {
      this.verifyCode(this.props.code);
    } else {
      this.setState({ loading: false });
    }
  }

  verifyCode = async (code) => {
    // let result = await auth.verifyPasswordResetCode(code)
    // if(result.error) {
    //   const { error } = result
    //   let codeError = this.copyKeyFromErrorCode(error.code)
    //   this.setState({ codeError, loading: false })
    // }
    // let email = result.email
    // this.setState({ email: email, password: '', verificationPassword: '', loading: false })
  };

  copyKeyFromErrorCode = (code) => {
    let key = code.replace(/\//g, ".").replace(/-/g, "_");
    return key;
  };

  togglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  onChange = (value, handle) => {
    this.setState({ [handle]: value });
  };

  save = async () => {
    const { password, verificationPassword } = this.state;
    let passwordInvalid = undefined;
    let verificationPasswordInvalid = undefined;
    if (!password) {
      passwordInvalid = "auth.password_missing";
    } else if (password.length < 6) {
      passwordInvalid = "auth.password_short";
    }
    if (password != verificationPassword) {
      verificationPasswordInvalid = "auth.verification_invalid";
    }
    this.setState({ passwordInvalid, verificationPasswordInvalid });
    if (!!passwordInvalid || !!verificationPasswordInvalid) {
      return;
    }
    const { code } = this.props;
    this.setState({ loading: true });
    // let result = await auth.confirmPasswordReset(code, password)
    this.setState({ loading: false, complete: true });
  };

  renderLoading = () => {
    return (
      <Grid className="stretch" justify="center" alignItems="center">
        <Grid item>
          <CircularProgress className="loading-spinner mt-3" />
        </Grid>
      </Grid>
    );
  };

  renderPasswordReset = () => {
    const {
      email,
      password,
      verificationPassword,
      showPassword,
      passwordInvalid,
      verificationPasswordInvalid,
    } = this.state;
    return (
      <div className="p-4 stretch column">
        <h4 className="bold">{"Data Privacy Box Easy"}</h4>
        <h5 className="mt-2">{t("auth.reset_pass")}</h5>
        <h6 className="light mt-2">
          {`${t("auth.for")} `}
          <span className="bold">{email}</span>
        </h6>
        <FormControl className="stretch mt-4">
          <InputLabel htmlFor="password">{t("auth.new_password")}</InputLabel>
          <Input
            error={!!passwordInvalid}
            id="password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(t) => {
              this.onChange(t.target.value, "password");
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.togglePassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {!!passwordInvalid ? (
            <FormHelperText error>{t(passwordInvalid)}</FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          error={!!verificationPasswordInvalid}
          helperText={
            !!verificationPasswordInvalid ? t(verificationPasswordInvalid) : ""
          }
          className="stretch mt-2"
          label={t("auth.verify_password")}
          type="password"
          value={verificationPassword}
          onChange={(t) => {
            this.onChange(t.target.value, "verificationPassword");
          }}
        />
        <Grid container className="stretch mt-4" justify="flex-end">
          <Grid item>
            {}
            <Button variant="contained" color="primary" onClick={this.save}>
              {t("general.save")}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderPasswordResetComplete = () => {
    return (
      <div className="stretch p-4 column">
        <h4>{t("auth.password_changed")}</h4>
        <h6 className="mt-2 light">{t("auth.password_changed_message")}</h6>
        {/* <Grid container className="stretch mt-4" justify="flex-end">
          <Grid item>
            <Button variant="contained" color="primary" onClick={this.toLogin}>{ t('auth.login') }</Button>
          </Grid>
        </Grid> */}
      </div>
    );
  };

  renderInvalid = (errorMessage) => {
    return (
      <div className="p-4 stretch column">
        <h4>{t("general.error_title")}</h4>
        <h6 className="mt-2 light">{errorMessage}</h6>
      </div>
    );
  };

  renderContent = () => {
    const { loading, codeError, complete } = this.state;
    const { mode } = this.props;
    if (loading) {
      return this.renderLoading();
    } else if (mode == "resetPassword") {
      if (!!codeError) {
        return this.renderInvalid(codeError);
      } else {
        if (complete) {
          return this.renderPasswordResetComplete();
        } else {
          return this.renderPasswordReset();
        }
      }
    } else {
      return this.renderInvalid(t("auth.invalid_action"));
    }
  };

  render() {
    return (
      <div className="login-background">
        <Container className="body-container centered-container" maxWidth="sm">
          <Card className="stretch">
            <CardContent className="stretch">
              {this.renderContent()}
            </CardContent>
          </Card>
        </Container>
      </div>
    );
  }
}

export default AuthAction;

import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/'

class QuestionsSidebar extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { questions, currentQuestion, onChangeQuestion, completion, answers, isQuestionComplete } = this.props
    return (
      <GridList cellHeight="auto" cols={1} spacing={10} className="questions-sidebar">
        { questions.map((question, i) => {
          return (
            <GridListTile className="questions-sidebar-row" key={`sidebar_question_${i}`}>
              <Grid container justify="flex-start" alignItems="center"
                onClick={onChangeQuestion(i) }
                className={`questions-sidebar-wrapper ${currentQuestion == i && !completion ? 'selected' : ''}`}>
                <Grid item>
                  <CheckCircleIcon size="small" className={`questions-sidebar-icon ${isQuestionComplete(questions[i], answers[i]) ? 'complete' : ''}`} />
                </Grid>
                <Grid item>
                  <span className={`questions-sidebar-label ${currentQuestion == i && !completion ? 'selected' : ''} ${isQuestionComplete(questions[i], answers[i]) ? 'complete' : ''}`}>{ question.title }</span>
                </Grid>
              </Grid>
            </GridListTile>
          )
        }) }
      </GridList>

    )
  }
}

export default QuestionsSidebar
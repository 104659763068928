import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import i18n from '../utils/i18n'
const t = i18n.instance().t

class ExpirationForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      date: moment(Date.now()),
      minDate: moment(Date.now()).add(1, 'days')
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.date != this.props.date) {
      this.setupDate(this.props.date)
    }
  }

  setupDate(timestamp) {
    let date
    if(!timestamp) {
      date = moment(Date.now())
    } else {
      date = moment(timestamp)
    }
    this.setState({ date: date, initialDate: date })
  }

  showSave = () => {
    const { date, initialDate } = this.state
    return !date.isSame(initialDate)
  }

  render() {
    const { open, onClose, onSave } = this.props
    const { date, minDate } = this.state
    return (
      <Dialog aria-labelledby="message-dialog" open={open} onClose={onClose}>
        <div className="popup column stretch">
          <span>{ t('document.expiration_date') }</span>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              className="mt-3"
              autoOk
              variant="inline"
              format="DD/MM/YYYY"
              value={date}
              minDate={minDate}
              onChange={(d) => this.setState({ date: d }) }
            />
          </MuiPickersUtilsProvider>
          <Grid container className="mt-3" spacing={2}>
            <Grid item>
              <Button variant="contained" onClick={onClose}>{ t('general.close')}</Button>
            </Grid>
            <Grid item>
              <Fade in={this.showSave()}>
                <Button variant="contained" color="primary" onClick={onSave(date.valueOf())}>{ t('general.save')}</Button>
              </Fade>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    )
  }
}

export default ExpirationForm
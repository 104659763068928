import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import i18n from '../utils/i18n'
const t = i18n.instance().t

const LegalFooter = (props) => {
  return (
    <Grid className="legal-footer" container justify="center" alignItems="center">
      <Grid item>
        <a target="blank" href={t('legal.security_url')} className="legal-link">{ t('legal.security')}</a>
      </Grid>
      <div className="legal-divider" />
      <Grid item>
        <a target="blank" href={t('legal.privacy_url')} className="legal-link">{ t('legal.privacy')}</a>
      </Grid>
      <div className="legal-divider" />
      <Grid item>
        <a target="blank" href={t('legal.legal_url')} className="legal-link">{ t('legal.legal')}</a>
      </Grid>
    </Grid>
  )
}

export default LegalFooter